import { mainConfig } from '../configs';
import { authHeader, handleResponse} from './authHeader';

export const paymentService = {
    getPayment,
    addPayment,
    deletePayment,
    payContactDue
};

function getPayment(id){
    const reqOpt = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/payments/${id}`, reqOpt).then(handleResponse)
}

function deletePayment(id){
    const reqOpt = {
        method: 'DELETE',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/payments/${id}`, reqOpt).then(handleResponse)
}

function addPayment(data) {
    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    }

    return fetch(`${mainConfig.apiUrl}/payments`, reqOpt).then(handleResponse)
}


function payContactDue(data, item) {
    const body = {
        accountNumber: data.accountNumber,
        contactId: item.id,
        duePaymentType: data.duePaymentType,
        amount: data.amount,
        paidOn: data.paidOn,
        method: data.method,
        note: data.note
    }
    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(body)
    }

    return fetch(`${mainConfig.apiUrl}/payments/pay-contact-due`, reqOpt).then(handleResponse)
}