import React from "react"
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap"
import ButtonTambah from "../../../components/custom/buttons/ButtonTambah"


class ModalInfo extends React.Component {
    state = {
        isOpen: false,
        number:this.props.number,
        name:this.props.name
    }

    toggleModal = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }))
    }

    sendToWA = (e, number, name) =>{
        e.preventDefault()
        // let text = "*Lapor Salah Transfer*, %0ATransfer yang saya lakukan tidak sesuai dengan yang tertera di aplikasi, mohon di cek. Berikut data rekening saya %0A %0A" + "*" + number + "* %0A a/n " + name
        // window.open(`https://api.whatsapp.com/send?phone=628999345999&text=${text}`)
        this.toggleModal()
    }

    render() {
        return (
            <Modal
                isOpen={this.state.isOpen}
                toggle={this.toggleModal}
                className="modal-dialog-centered">
                <ModalHeader toggle={this.toggleModal}>
                <span className="margin-left-right-14">Informasi</span>
                </ModalHeader>
                <ModalBody>
                    <p className="mx-1 my-0 font-normal">Mohon sertakan <span className="bold">Bukti Transfer</span> secara manual saat mengirimkan laporan salah transfer CS Kipa</p>
                </ModalBody>
                <ModalFooter>
                    <ButtonTambah text="Mengerti" onClick={e => this.sendToWA(e, this.props.number, this.props.name)}/>
                </ModalFooter>
            </Modal>
        )
    }

}

export default ModalInfo