// import { authHeader } from '../_helpers';
import { mainConfig } from '../configs';
import { authHeader, handleResponse} from './authHeader';

export const contactServices = {
    getSupplier,
    getCustomer,
    editSupplier,
    addSupplier,
    deleteSupplier,
    getSupplierLedger,
    addCustomer,
    editCustomer,
    deleteCustomer,
    getCustomerLedger,
    importSupplier,
    importPelanggan,
    imageCustomer
};

function getSupplier(page = 1, limit = 10, sort = "", search = "", creditDebt = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/suppliers?page=${page}&per_page=${limit}&sort=${sort}&search=${encodeURIComponent(search)}&creditDebt=${creditDebt}`, reqOpt).then(handleResponse)
}

function getCustomer(page = 1, limit = 10, sort = "", search = "", customerGroupId="", creditDebt = ""){
    const reqOpt = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/customers?page=${page}&per_page=${limit}&sort=${sort}&search=${encodeURIComponent(search)}&customerGroupId=${customerGroupId}&creditDebt=${creditDebt}`, reqOpt).then(handleResponse)
}

function addSupplier(data){
    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    }

    return fetch(`${mainConfig.apiUrl}/suppliers`, reqOpt).then(handleResponse)
}

function importSupplier(data) {
    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    }

    return fetch(`${mainConfig.apiUrl}/suppliers/import`, reqOpt).then(handleResponse)
}

function importPelanggan(data) {
    // const req
    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    }

    return fetch(`${mainConfig.apiUrl}/customers/import`, reqOpt).then(handleResponse)
}

function editSupplier(id,data){
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    }

    return fetch(`${mainConfig.apiUrl}/suppliers/${id}`, reqOpt).then(handleResponse)
}

function deleteSupplier(id){
    const reqOpt = {
        method: 'DELETE',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/suppliers/${id}`, reqOpt).then(handleResponse)
}

function addCustomer(data){
    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    }

    return fetch(`${mainConfig.apiUrl}/customers`, reqOpt).then(handleResponse)
}

function editCustomer(id,data){
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    }

    return fetch(`${mainConfig.apiUrl}/customers/${id}`, reqOpt).then(handleResponse)
}

function deleteCustomer(id){
    const reqOpt = {
        method: 'DELETE',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/customers/${id}`, reqOpt).then(handleResponse)
}

function getCustomerLedger(id,filter,showPayment = false, sort="", search="", startDate = "",endDate = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/suppliers/${id}/ledger?filter=${filter}&sort=${sort}&search=${encodeURIComponent(search)}&startDate=${startDate}&endDate=${endDate}&showPayment=${showPayment}`, reqOpt).then(handleResponse)
}

function getSupplierLedger(id,filter,showPayment = false, sort="", search="", startDate = "",endDate = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/suppliers/${id}/ledger?filter=${filter}&sort=${sort}&search=${encodeURIComponent(search)}&startDate=${startDate}&endDate=${endDate}&showPayment=${showPayment}`, reqOpt).then(handleResponse)
}

function imageCustomer(id, formData) {
    const data = new FormData()
    data.append('image', formData)
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(true), 
        body: data
    }
    return fetch(`${mainConfig.apiUrl}/customers/upload-image/${id}`, reqOpt).then(handleResponse)
}