import { mainConfig } from '../configs';
import { authHeader, handleResponse} from './authHeader';

export const productService = {
    getProducts,
    addProduct,
    editProduct,
    deleteProduct,
    productPriceGroup,
    getProductTable,
    updatePriceGroups,
    updateStocks,
    inactivateProduct,
    getPriceGroupPerProduct,
    importProduct,
    editMassProduct,
    importStock,
    importPriceGroup,
    getInventory,
    getViewProduct,
    imageProduct,
    getInventoryMassal,
    deleteProductMassal,
    getProductForInventory
};

function getProducts(page = 1, limit = 10, categoryId = -1, sort = "", search = "", isActive="", type="") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/products?&page=${page}&per_page=${limit}&categoryId=${categoryId}&sort=${sort}&search=${encodeURIComponent(search)}&isActive=${isActive}&type=${type}`, reqOpt).then(handleResponse)
}
function getProductForInventory(page = 1, limit = 10, enableStock = true) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/products?&page=${page}&per_page=${limit}&enableStock=${enableStock}`, reqOpt).then(handleResponse)
}

function addProduct(data, inventory, productPrice,productExpired) {

    let body = {
        alertQuantity: data.alertQuantity,
        categoryId: data.categoryId,
        enableStock: data.enableStock,
        isActive: data.isActive,
        name: data.name,
        productDescription:data.productDescription,
        sku: data.sku,
        showInMarketplace:data.showInMarketplace,
        unitId: data.unitId,
        inventory : inventory,
        productPrice : productPrice, 
        productExpired: productExpired, 
        isUnique: data.isUnique
    }
    const reqOpt = {
        method: 'POST',
        headers: authHeader(), 
        body: JSON.stringify(body)
    }
    return fetch(`${mainConfig.apiUrl}/products`, reqOpt).then(handleResponse)
}

function editProduct(id,data, inventory, productPrice, productExpired) {
    let inventories = inventory.map(item=>{
        return {
            avcoPrice: item.avcoPrice,
            locationId: item.locationId,
            name: item.name,
            quantity: item.quantity - item.oldQty
        }
    })
        
    
    let body = {
        alertQuantity: data.alertQuantity,
        categoryId: data.categoryId,
        enableStock: data.enableStock,
        isActive: data.isActive,
        name: data.name,
        productDescription:data.productDescription,
        sku: data.sku,
        showInMarketplace:data.showInMarketplace,
        unitId: data.unitId,
        inventory : inventories,
        productPrice : productPrice,
        productExpired: productExpired,
        isUnique: data.isUnique
    }
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(), 
        body: JSON.stringify(body)
    }
    return fetch(`${mainConfig.apiUrl}/products/${id}`, reqOpt).then(handleResponse)
}


function imageProduct(id, formData) {
    const data = new FormData()
    data.append('image', formData)
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(true), 
        body: data
    }
    return fetch(`${mainConfig.apiUrl}/products/upload-image/${id}`, reqOpt).then(handleResponse)
}

function editMassProduct(products) {
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(), 
        body: JSON.stringify({product:products})
    }
    return fetch(`${mainConfig.apiUrl}/products/bulk`, reqOpt).then(handleResponse)
}

function deleteProduct(id) {
    const reqOpt = {
        method: 'DELETE',
        headers: authHeader(),
    }
    return fetch(`${mainConfig.apiUrl}/products/${id}`, reqOpt).then(handleResponse)
}
function deleteProductMassal(id) {
    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({productId: id})
    }
    return fetch(`${mainConfig.apiUrl}/products/bulk-delete`, reqOpt).then(handleResponse)
}

function productPriceGroup(id) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/products/${id}/pricegroup`, reqOpt).then(handleResponse)
}

function getProductTable(id, type = "purchase", page = 1, limit = 10, categoryId = -1, sort = "", search = "", selectedProduct =" ") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }
    return fetch(`${mainConfig.apiUrl}/products?locationId=${id}&type=${type}&page=${page}&per_page=${limit}&categoryId=${categoryId}&sort=${sort}&search=${encodeURIComponent(search)}&excludedProduct=${selectedProduct.toString()}`, reqOpt).then(handleResponse)
}


function updatePriceGroups(data) {
    const body = data.map(item => {
        
        return {
            id: item.productId,
            priceGroup: item.priceGroups
        }
    })

    const reqOpt = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({product: body})
    }

    return fetch(`${mainConfig.apiUrl}/products/bulk-price`, reqOpt).then(handleResponse)
}

function inactivateProduct(ids) {
    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(ids)
    }
    return fetch(`${mainConfig.apiUrl}/products/inactive`, reqOpt).then(handleResponse)
}


function updateStocks(data, inventories) {
    let body = inventories.map(item => {
        return {
            id : item.id,
            inventory: item.inventory.map(items => {
                return {
                    name: items.name,
                    locationId: items.locationId,
                    quantity: items.quantity - items.oldQty,
                    avcoPrice:items.avcoPrice
                }
            })
        }
    })

    const reqOpt = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({product : body})
    }

    return fetch(`${mainConfig.apiUrl}/products/bulk-inventory`, reqOpt).then(handleResponse)
}

function getPriceGroupPerProduct(id) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }
    return fetch(`${mainConfig.apiUrl}/products/${id}/pricegroup`, reqOpt).then(handleResponse)
}
function getViewProduct(id) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }
    return fetch(`${mainConfig.apiUrl}/products/${id}`, reqOpt).then(handleResponse)
}

function getInventory(id) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }
    return fetch(`${mainConfig.apiUrl}/products/${id}`, reqOpt).then(handleResponse)
}


function getInventoryMassal(id) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(`${mainConfig.apiUrl}/products/bulk-view?productId=${id}`, reqOpt).then(handleResponse)
}


function importProduct(data) {
    // const req
    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    }

    return fetch(`${mainConfig.apiUrl}/products/import-product`, reqOpt).then(handleResponse)
}

function importStock(data, outletId) {
    // const req
    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    }

    return fetch(`${mainConfig.apiUrl}/products/import-stock/${outletId}`, reqOpt).then(handleResponse)
}

function importPriceGroup(products, priceGroupId) {
    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(products)
    }

    return fetch(`${mainConfig.apiUrl}/pricegroups/import-product-price/${priceGroupId}`, reqOpt).then(handleResponse)
}


