import { mainConfig } from '../configs';
import { authHeader, handleResponse} from './authHeader';

export const priceGroupService = {
    getPriceGroup,
    addPriceGroup,
    editPriceGroup,
    deletePriceGroup
};

function getPriceGroup(page = 1, limit = 10, sort = "", search = ""){
    const reqOpt = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/pricegroups?page=${page}&per_page=${limit}&sort=${sort}&search=${encodeURIComponent(search)}`, reqOpt).then(handleResponse)
}

function addPriceGroup(name, type = "fixed") {
    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ name, type })
    }

    return fetch(`${mainConfig.apiUrl}/pricegroups`, reqOpt).then(handleResponse)
}

function editPriceGroup(id,name, type = "fixed") {
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ name, type })
    }

    return fetch(`${mainConfig.apiUrl}/pricegroups/${id}`, reqOpt).then(handleResponse)
}

function deletePriceGroup(id) {
    const reqOpt = {
        method: 'DELETE',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/pricegroups/${id}`, reqOpt).then(handleResponse)
}