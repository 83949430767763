import React from "react"
import {
    Button, Spinner,
  } from "reactstrap"
  
class ButtonTambah extends React.Component {
    render() {
        return(
            <Button color="primary" className={this.props.className} disabled={this.props.isLoading || this.props.isDisabled} onClick={this.props.onClick} type="submit">
                {this.props.isLoading && <Spinner color="white" size="sm" />}
                <span className={this.props.isLoading ? "ml-50" : ""}>{this.props.text}</span>
            </Button>
        )
    }
}

export default ButtonTambah