// import { authHeader } from '../_helpers';
import { mainConfig } from '../configs';
import { authHeader, handleResponse} from './authHeader';

export const costServices = {
    getCategoryCost,
    addCategoryCost,
    getIncome,
    getExpense,
    addExpense,
    addIncome,
    deleteExpense,
    deleteIncome,
    deleteExpenseIncomeCat
};
function getCategoryCost(page = 1, limit = 10, sort = "", search = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/expense-income-cat?page=${page}&per_page=${limit}&sort=${sort}&search=${encodeURIComponent(search)}`, reqOpt).then(handleResponse)
}

function addCategoryCost(name, type) {
    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ name, type })
    }

    return fetch(`${mainConfig.apiUrl}/expense-income-cat`, reqOpt).then(handleResponse)
}

function getExpense(page = 1, limit = 10, locationId = -1, categoryId = -1, createdBy = -1, startDate = "", endDate = "", sort = "", search = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/expense?page=${page}&per_page=${limit}&locationId=${locationId}&categoryId=${categoryId}&createdBy=${createdBy}&startDate=${startDate}&endDate=${endDate}&sort=${sort}&search=${encodeURIComponent(search)}`, reqOpt).then(handleResponse)
}

function addExpense(body) {
    // body.expenseFor = body.contactId
    body.expenseIncomeCategoryId = body.categoryId
    delete body.contactId
    delete body.categoryId

    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(body)
    }

    return fetch(`${mainConfig.apiUrl}/expense`, reqOpt).then(handleResponse)
}

function addIncome(body) {
    // body.incomeFor = body.contactId
    body.expenseIncomeCategoryId = body.categoryId
    delete body.contactId
    delete body.categoryId

    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(body)
    }

    return fetch(`${mainConfig.apiUrl}/income`, reqOpt).then(handleResponse)
}

function getIncome(page = 1, limit = 10, locationId = -1, categoryId = -1, createdBy = -1, startDate = "", endDate = "", sort = "", search = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/income?page=${page}&per_page=${limit}&locationId=${locationId}&categoryId=${categoryId}&createdBy=${createdBy}&startDate=${startDate}&endDate=${endDate}&sort=${sort}&search=${encodeURIComponent(search)}`, reqOpt).then(handleResponse)
}
function deleteExpenseIncomeCat(id){
    const reqOpt = {
        method: 'DELETE',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/expense-income-cat/${id}`, reqOpt).then(handleResponse)
}

function deleteExpense(id){
    const reqOpt = {
        method: 'DELETE',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/expense/${id}`, reqOpt).then(handleResponse)
}

function deleteIncome(id){
    const reqOpt = {
        method: 'DELETE',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/income/${id}`, reqOpt).then(handleResponse)
}