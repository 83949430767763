import { combineReducers } from "redux"
import { login } from "./loginReducer"
import { register } from "./registerReducer"
import { getSaldo } from "./userReducer"

const authReducers = combineReducers({
  login,
  register,
  getSaldo
})

export default authReducers
