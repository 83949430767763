import React from "react"
import {
    Button,
  } from "reactstrap"
  
class ButtonBatalkan extends React.Component {
    render() {
        return(
            <Button.Ripple onClick={this.props.onClick} className="btn-batal font-normal text-bold-500" color="">
                {this.props.text ? this.props.text : "Batalkan"}
            </Button.Ripple>
        )
    }
}

export default ButtonBatalkan