import React from "react"
import {
  NavItem,
  NavLink
} from "reactstrap"
import {Calendar, Menu} from "react-feather"
import moment from "moment-timezone"
import 'moment/locale/id'
moment.locale('id')

class NavbarBookmarks extends React.PureComponent {
  state = {
    showBookmarks: false,
    value: "",
    noSuggestions: false,
    isStarred: false,
    suggestions: [],
    day: new Date().getDay(),
    date: new Date().getDate(),
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
    time: new Date().getHours() + ":" + new Date().getMinutes()
  }

  componentDidMount() {
    // setInterval(() => {
    //   let day = new Date().getDay()
    //   day = day === 0 ? day : --day
    //   this.setState({
    //     day,
    //     date: new Date().getDate(),
    //     month: new Date().getMonth(),
    //     year: new Date().getFullYear(),
    //     time: new Date().getHours() + ":" + new Date().getMinutes()
    //   })
    // }, 1000)
  }

  render() {
    let {
      sidebarVisibility
    } = this.props

    const months = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", 'Desember']
    const days = ["Minggu","Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"]

    return (
      <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
        <ul className="navbar-nav d-xl-none">
          <NavItem className="mobile-menu mr-auto">
            <NavLink
              className="nav-menu-main menu-toggle hidden-xs is-active"
              onClick={sidebarVisibility}
            >
              <Menu className="ficon" />
            </NavLink>
          </NavItem>
        </ul>
        <ul className="nav navbar-nav bookmark-icons">
          <NavItem>
            <Calendar size={16} className="d-inline-block v-align-middle"/>
            <p className="mb-0 d-inline-block font-normal v-align-middle ml-1">{days[(this.state.day)]}, {this.state.date} {months[this.state.month]} {this.state.year}</p>
          </NavItem>
        </ul>
      </div>
    )
  }
}

export default NavbarBookmarks
