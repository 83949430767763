import { mainConfig } from '../configs';
import { authHeader, handleResponse} from './authHeader';

export const notificationService = {
    getNotification,
    readNotification
};

function getNotification(page = 1, limit = 10, sort = "", search = ""){
    const reqOpt = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/notifications?page=${page}&per_page=${limit}&sort=${sort}&search=${encodeURIComponent(search)}`, reqOpt).then(handleResponse)
}


function readNotification(id){
    const reqOpt = {
        method: 'POST',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/notifications/read/${id}`, reqOpt).then(handleResponse)
}