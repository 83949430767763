import React from "react"
import NumberFormat from "react-number-format"
  
class RupiahLabel extends React.Component {
    render() {
        return(
            <NumberFormat className={this.props.className} value={parseFloat(this.props.value)} displayType={"text"} thousandSeparator={true} prefix={'Rp '} />
        )
    }
}

export default RupiahLabel