export const login = (state = { isLoading: false }, action) => {
  switch (action.type) {
    case "LOGIN_REQUEST": {
      return { ...state, isLoading: true, values: action.payload }
    }
    case "LOGIN_SUCCESS": {
      return { ...state, isLoading: false, values: action.payload }
    }
    case "LOGIN_FAILURE": {
      return {...state, isLoading: false, values: action.payload }
    }
    case "LOGOUT": {
      return { }
    }
    case "CHANGE_ROLE": {
      return { ...state, userRole: action.userRole }
    }
    default: {
      return state
    }
  }
}
