export const getSaldo = (state = { isLoading: false }, action) => {
    switch (action.type) {
      case "USER_REQUEST": {
        return { ...state, isLoading: true, values: action.payload }
      }
      case "USER_SUCCESS": {
        return { ...state, isLoading: false, values: action.payload }
      }
      case "USER_FAILURE": {
        return {...state, isLoading: false, values: action.payload }
      }
      default: {
        return state
      }
    }
  }
  