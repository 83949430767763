// import { authHeader } from '../_helpers';
import { mainConfig } from '../configs';
import { authHeader, handleResponse} from './authHeader';

export const cgroupServices = {
    getAll,
    add,
    update,
    destroy
};

function getAll(page = 1, limit = 10, sort = "", search = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/custgroups?page=${page}&per_page=${limit}&sort=${sort}&search=${encodeURIComponent(search)}`, reqOpt).then(handleResponse)
}

function add(name) {
    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ name })
    }

    return fetch(`${mainConfig.apiUrl}/custgroups`, reqOpt).then(handleResponse)
}

function update(id, name) {
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ name })
    }

    return fetch(`${mainConfig.apiUrl}/custgroups/${id}`, reqOpt).then(handleResponse)
}

function destroy(id) {
    const reqOpt = {
        method: 'DELETE',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/custgroups/${id}`, reqOpt).then(handleResponse)
}
