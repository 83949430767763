import { mainConfig } from '../configs';
import { authHeader, handleResponse} from './authHeader';

export const packageService = {
    upgradePackage
};

function upgradePackage(packages){
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({package: packages})
    }

    return fetch(`${mainConfig.apiUrl}/business/package`, reqOpt).then(handleResponse)
}