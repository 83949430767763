import { userService } from '../../services'

export const getSaldo = () => {
  return dispatch => {
    dispatch({
      type: "USER_REQUEST",
      payload: {
          data: null,
          status: false,
          message: ''
      }
    })

    userService.getSaldo()
    .then( resp => {
      if (resp.data.user !== null) {
        localStorage.setItem('USER_SETTING', JSON.stringify(resp.data.user))
      } 
      dispatch({
        type: "USER_SUCCESS",
        payload: {
          data: resp.data,
          status: resp.status,
          message: resp.message,
        
        }
      })
    }, error => {
      dispatch({
        type: "USER_FAILURE",
        payload: {
          data: null,
          status: false,
          message: error.toString()
        }
      })
    })
  }
}