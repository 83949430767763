import React from "react"
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Badge,
  Media,
  NavItem
} from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import { User, Power, Bell, ChevronDown, } from "react-feather"
import { logout } from "../../../actions/auth/loginActions"
import { connect } from "react-redux"
import { history } from "../../../history"
import { notificationService } from "../../../services/"
import ConfirmationModal from "../../../components/custom/Modal/ConfirmationModal"
import moment from "moment-timezone"
import NotifEmpty from "../../../components/custom/Icon/ic_empty_notif.svg"
import { ReactComponent as StockIcon } from "../../../components/custom/Icon/ic_package_blue.svg"
import { ReactComponent as SellIcon } from "../../../components/custom/Icon/ic_sell.svg"
import { ReactComponent as DownloadIcon } from "../../../components/custom/Icon/ic_download.svg"
import Basic from "../../../components/custom/Icon/basic.svg"
import Pro from "../../../components/custom/Icon/pro.svg"
import Enterprise from "../../../components/custom/Icon/enterprise.svg"
import 'moment/locale/id'
moment.locale('id')

const UserDropdown = props => {
  return (
    <DropdownMenu right style={{ padding: "0px", marginTop: 10, right: 16 }}>
      <DropdownItem tag="a"
        onClick={e => history.push("/profile")}
      >
        <User size={14} className="mr-50" />
        <span className="align-middle">Edit Profile</span>
      </DropdownItem>
      <DropdownItem
        tag="a"
        href="#"
        onClick={e => props.logout()}
      >
        <Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  )
}

class NavbarUser extends React.PureComponent {
  state = {
    data: [],
    length: 0
  }

  constructor(props) {
    super(props)
    this._modalConfirm = React.createRef()
  }

  componentDidMount() {
    notificationService.getNotification(1, 5, 'readAt', 'unread').then(resp => {
      if (resp.status) {
        this.setState({ data: resp.data, length: resp.total })
      }
    })
  }

  toggleConfirmation = () => {
    this._modalConfirm.current.toggleModal()
  }


  pad = (n, width, z) => {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  clickNotification = (item) => {
    if (item.type === "stockMin") {
      notificationService.readNotification(item.id).then(resp => {
        if (resp.status) {
          history.push("/reports/stock")
        }
      })
    }
  }

  readAll = () => {
    notificationService.readNotification(-1).then(resp => {
      if (resp.status) {
        history.push("/notification")
      }
    })
  }


  render() {
    return (
      <React.Fragment>
        <ul className="nav navbar-nav navbar-nav-user  float-left">
          <div className="mr-auto float-left d-flex align-items-center">
            <NavItem data-tut="package">
              {this.props.user.business.package === "basic" ? <img src={Basic} alt=""></img> : this.props.user.business.package === "pro" ? <img src={Pro} alt=""></img> : <img src={Enterprise} alt=""></img>}
              <p className="mb-0 d-inline-block black font-normal text-bold-500 v-align-middle mr-1 ml-1">{this.props.user.business.package === "basic" ? "Kipa Basic" : this.props.user.business.package === "pro" ? "Kipa Pro" : "Kipa Enterprise"}</p>
            </NavItem>
          </div >
          <div data-tut="username" className="mr-auto float-left d-flex align-items-center">
          <UncontrolledDropdown
            tag="li"
            className="dropdown-notification nav-item"
          >
            <DropdownToggle tag="a" className="nav-link nav-link-label">
              <Bell size={21} color="#555597" />
              {+this.state.length > 0 &&
                <Badge pill color="primary" className="badge-up">
                  {" "}
                  {+this.state.length}{" "}
                </Badge>}
            </DropdownToggle>
            <DropdownMenu tag="ul" right className="dropdown-menu-media">
              <PerfectScrollbar
                className="media-list overflow-hidden position-relative"
                options={{
                  wheelPropagation: false
                }}
              >
                {(this.state.data && this.state.data.length > 0) ? this.state.data.map((item, index) => {
                  let data = item.data && JSON.parse(item.data)
                  return (<div key={index} className="d-flex justify-content-between" onClick={e => this.clickNotification(item)}>
                    <Media className="d-flex align-items-start">
                      <Media body>
                        <p className="text-bold-500 info mb-0">
                          {item.type === "stockMin" ? <StockIcon style={{ marginRight: '8px' }} /> : item.type === "sells" ? <SellIcon style={{ marginRight: '8px' }} /> : <DownloadIcon style={{ marginRight: '8px' }} />}
                          {data.title}</p>
                        <p className="notification-text black" style={{ marginTop: '10px' }}>
                          {data.message}
                        </p>
                      </Media>
                      <small>
                        {moment(item.createdAt).format("DD.MMM.YYYY HH:mm")}
                      </small>
                    </Media>
                  </div>);
                }) : (<div className="d-flex justify-content-between">
                  <Media className="d-flex align-items-start">
                    <Media body className="py-1">
                      <img src={NotifEmpty} className="mx-auto d-block" alt='kipa notif empty' />
                      <p className="text-center mt-1 black text-bold-500 font-medium-1">Belum Ada Notifikasi</p>
                    </Media>
                  </Media>
                </div>)}
              </PerfectScrollbar>
              <li className="dropdown-menu-footer" onClick={this.readAll}>
                <DropdownItem tag="a" className="p-2 text-center" style={{ backgroundColor: '#f7f7fa' }}>
                  <span className="align-middle font-normal text-bold-500 info">Lihat Semua Notifikasi</span>
                </DropdownItem>
              </li>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown tag="li" className="dropdown-user nav-item" style={{ marginTop: 5 }}>
            <DropdownToggle tag="a" className="nav-link dropdown-user-link">
              <div className="user-nav d-sm-flex d-none">
                <span className="user-name black text-bold-500" style={{ color: "#424242" }}>
                  {this.props.user.name}
                </span>
                <span className="user-status">{this.props.user.email}</span>
              </div>
              <ChevronDown size="18" style={{ color: "#555597" }} />
            </DropdownToggle>
            <UserDropdown {...this.props} logout={this.toggleConfirmation} />
          </UncontrolledDropdown>
          </div>
        </ul>
        <ConfirmationModal ref={this._modalConfirm} title="Tunggu dulu!" content="Apakah kamu yakin ingin keluar dari Kipa ?" btnOkText="Ya, Logout" onClick={e => this.props.logout()} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.auth.login.isLoading,
    result: state.auth.login
  }
}


export default connect(mapStateToProps, { logout })(NavbarUser)
