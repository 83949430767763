import React from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Input,
} from "reactstrap"
import ButtonBatalkan from "../../../components/custom/buttons/ButtonBatalkan"
import ButtonTambah from "../../../components/custom/buttons/ButtonTambah"
import ModalInfo from "./ModalInfo"
class ManageFailed extends React.Component {
  constructor(props) {
    super(props)
    this._modalInfo = React.createRef()
}
  state = {
    isLoading: false,
    name: "",
    number: ""
  }

  toggleModal = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }))
  }

  reportProblem = () => {
    this.toggleModal()
    this._modalInfo.current.toggleModal()
  }


  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.isOpen}
          toggle={this.toggleModal}
          className="modal-dialog-centered">
          <ModalHeader toggle={this.toggleModal}>
            <span className="margin-left-right-14">Lapor Salah Transfer</span>
          </ModalHeader>
            <ModalBody className="margin-left-right-14">
                 <FormGroup>
                <Label for="namagroup" className="font-normal">Nama Lengkap<span className="mandatory"> *</span></Label>
                <Input
                  type="text"
                  id="namagroup"
                  autoComplete="off"
                  placeholder="Nama Lengkap"
                  value={this.state.name}
                  onChange={e => this.setState({ name: e.target.value })} />
              </FormGroup>
               <FormGroup>
                <Label for="namagroup" className="font-normal">Nomor Rekening<span className="mandatory">*</span></Label>
                <Input
                  type="text"
                  id="namagroup"
                  autoComplete="off"
                  placeholder="Nomor Rekening"
                  value={this.state.number}
                  onChange={e => this.setState({ number: e.target.value })} />
              </FormGroup>
            </ModalBody>
            <ModalFooter className="margin-left-right-14">
              <ButtonBatalkan onClick={this.toggleModal} />
              <ButtonTambah text="Kirim Laporan" onClick={this.reportProblem}/>{" "}
            </ModalFooter>
        </Modal>
        <ModalInfo ref={this._modalInfo} name={this.state.name} number={this.state.number}/>
      </React.Fragment>
    )
  }
}

export default ManageFailed