import { mainConfig } from '../configs';
import { authHeader, handleResponse} from './authHeader';

export const unitService = {
    
    getUnit,
    addUnit,
    updateUnit,
    destroy
};

function getUnit(page = 1, limit = 10, sort = "", search = ""){
    const reqOpt = {
        method: 'Get',
        headers: authHeader()
    }
    return fetch(`${mainConfig.apiUrl}/units?page=${page}&per_page=${limit}&sort=${sort}&search=${encodeURIComponent(search)}`, reqOpt).then(handleResponse)
}

function addUnit(name, shortName) {
    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ name, shortName})
    }

    return fetch(`${mainConfig.apiUrl}/units`, reqOpt).then(handleResponse)
}

function updateUnit(id, name, shortName) {
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ name, shortName})
    }

    return fetch(`${mainConfig.apiUrl}/units/${id}`, reqOpt).then(handleResponse)
}

function destroy(id) {
    const reqOpt = {
        method: 'DELETE',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/units/${id}`, reqOpt).then(handleResponse)
}