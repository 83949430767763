import cuanLib from "cuan-kipa-lib"
import { decrypt } from "../utility/Encryptor"
import 'dotenv/config'

// if(userSetting && userSetting !== "")


export const cuan = () => {
    const userSetting = JSON.parse(localStorage.getItem('USER_SETTING'))
    let token = userSetting.business.cuanToken !== "" && decrypt(userSetting.business.cuanToken)
    let env = process.env.NODE_ENV
    if(window.location.origin.includes('dev')) {
        env = "development"
    } 

    cuanLib.initialize(token, env)
    if (env !== "production") {
        cuanLib.setBaseUrl("https://cuanapp.kipa.id/v1")
    }
    
    return cuanLib
}