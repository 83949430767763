// import { authHeader } from '../_helpers';
import { mainConfig } from '../configs';
import { authHeader, handleResponse} from './authHeader';

export const receiptServices = {
    getReceipt,
    addReceipt
};

function getReceipt() {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/invoices`, reqOpt).then(handleResponse)
}

function addReceipt(data, file){
    delete data.businessName
    delete data.address
    delete data.mobile
    delete data.id
    delete data.businessId
    delete data.createdAt
    delete data.updatedAt
    delete data.outlet
    delete data.logo
    const formData = new FormData()
    Object.keys(data).forEach(key => formData.append(key, data[key]));
    if(file) formData.append('logo', file)

    const reqOpt = {
        method: 'PUT',
        headers: authHeader(true),
        body: formData
    }

    return fetch(`${mainConfig.apiUrl}/invoices`, reqOpt).then(handleResponse)
}