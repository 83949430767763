// import { authHeader } from '../_helpers';
import { mainConfig } from '../configs';
import { authHeader, handleResponse} from './authHeader';

export const stockServices = {
    getCorrectStock,
    viewCorrectStock,
    getPurchases,
    viewPurchase,
    getReturnPurchase
};

function getCorrectStock(page = 1, limit = 10, locationId = -1, sort = "", search = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/stockopname?page=${page}&per_page=${limit}&sort=${sort}&search=${encodeURIComponent(search)}&locationId=${locationId}`, reqOpt).then(handleResponse)
}

function viewCorrectStock(id) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/stockopname/${id}`, reqOpt).then(handleResponse)
}

function getPurchases(page = 1, limit = 10, sort = "", search = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/purchases?page=${page}&per_page=${limit}&sort=${sort}&search=${encodeURIComponent(search)}`, reqOpt).then(handleResponse)
}

function viewPurchase(id) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }
    return fetch(`${mainConfig.apiUrl}/purchases/${id}`, reqOpt).then(handleResponse)
}
function getReturnPurchase(page = 1, limit = 10, locationId = -1, supplierId = -1, paymentStatus = '', startDate = "", endDate = "", sort = "", search = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/purchase-return?page=${page}&per_page=${limit}&locationId=${locationId}&supplierId=${supplierId}&paymentStatus=${paymentStatus}&startDate=${startDate}&endDate=${endDate}&sort=${sort}&search=${encodeURIComponent(search)}`, reqOpt).then(handleResponse)
}
