// import { authHeader } from '../_helpers';
import { mainConfig } from '../configs';
import { authHeader, handleResponse, handleResponseToken} from './authHeader';

export const businessService = {
    getManageBusiness,
    updateManageBusiness,
    getPaymentMethod,
    updatePaymentMethod,
    updateAccess,
    getPermission,
    getAdmin
};

function getManageBusiness() {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/business`, reqOpt).then(handleResponse)
}

function getPermission() {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/permissions`, reqOpt).then(handleResponse)
}

function updateManageBusiness(name, businessCategoryId, setting) {
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(), 
        body: JSON.stringify({name, businessCategoryId, setting})
    }
    return fetch(`${mainConfig.apiUrl}/business`, reqOpt).then(handleResponseToken)
}

function getPaymentMethod() {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/paymentmethods`, reqOpt).then(handleResponse)
}

function getAdmin() {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/admins`, reqOpt).then(handleResponse)
}

function updatePaymentMethod(data) {
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({paymentMethod: data})
    }

    return fetch(`${mainConfig.apiUrl}/business/payment`, reqOpt).then(handleResponse)
}

function updateAccess(permission) {
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({permission})
    }

    return fetch(`${mainConfig.apiUrl}/business/permission`, reqOpt).then(handleResponse)
}



