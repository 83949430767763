// import { authHeader } from '../_helpers';
import { mainConfig } from '../configs';
import { authHeader, handleResponse} from './authHeader';

export const purchaseReturnServices = {
    getReturnPurchase,
    getDetailReturnPurchase,
    updatePurchaseReturn,
    deletePurchaseReturn
};

function getReturnPurchase(page = 1, limit = 10, locationId = -1, supplierId = -1, paymentStatus = '', date = []) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/purchase-return?page=${page}&per_page=${limit}&locationId=${locationId}&supplierId=${supplierId}&paymentStatus=${paymentStatus}&startDate=${date[0]}&endDate=${date[1]}`, reqOpt).then(handleResponse)
}


function getDetailReturnPurchase(id) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/purchase-return/${id}`, reqOpt).then(handleResponse)
}


function updatePurchaseReturn(body) {
    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(body)
    }

    return fetch(`${mainConfig.apiUrl}/purchase-return`, reqOpt).then(handleResponse)
}

function deletePurchaseReturn(id) {
    const reqOpt = {
        method: 'DELETE',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/purchase-return/${id}`, reqOpt).then(handleResponse)
}
