import React, { Component } from "react"
import classnames from "classnames"
import { ContextLayout } from "../../../../utility/context/Layout"
import { connect } from "react-redux"
import SidebarHeader from "./SidebarHeader"
import Hammer from "react-hammerjs"
import SideMenuContent from "./sidemenu/SideMenuContent"
import PerfectScrollbar from "react-perfect-scrollbar"
import { Col, Button, Row, Spinner, NavLink } from "reactstrap"
import IconKipa from "../../../../components/custom/Icon/icon.png"
import { getSaldo } from "../../../../actions/auth/userAction"
import jwt from 'jsonwebtoken'
import RupiahLabel from "../../../../components/custom/Text/RupiahLabel"
import ManageTopup from '../../../../views/KipaBalance/Balance/ManageTopup'
import { history } from "../../../../history"
import ManageConfirm from "../../../../views/KipaBalance/Balance/ManageModal"

class Sidebar extends Component {
  constructor(props) {
    super(props)
    this._modalManage = React.createRef()
    this._modalConfirm = React.createRef()
  }
  static getDerivedStateFromProps(props, state) {
    if (props.activePath !== state.activeItem) {
      return {
        activeItem: props.activePath
      }
    }
    return null
  }
  state = {
    active: "1",
    modal: false,
    width: window.innerWidth,
    activeIndex: null,
    hoveredMenuItem: null,
    activeItem: this.props.activePath,
    menuShadow: false,
    user: this.props.user,
    accountsSidebar: [],
    data: null,
    status: false,
    isOpen: "false",
    ScrollbarTag: PerfectScrollbar
  }
  updateWidth = () => {
    this.setState(prevState => ({
      width: window.innerWidth
    }))
    this.checkDevice()
  }

  showModal = () => {
    let user = this.state.user
    if (this.state.user === "enterprise") {
      const accStr = localStorage.getItem("USER_ACCOUNTS")
      if (accStr) {
        const accountsSidebar = JSON
          .parse(accStr)
          .filter(it => it.accountNumber !== "1-10001" && it.category.id === 1)
          .map(item => {
            return { label: item.name, name: "accountNumber", value: item.accountNumber }
          })

        accountsSidebar.unshift({ value: "-1", name: "accountNumber", label: "Pilih Akun Keuangan" })
        this.setState({ accountsSidebar })
        // if (this.state.status === false ) {
        //   this._modalManage.current.toggleModal(user, accountsSidebar)
        // }else{
        this._modalManage.current.toggleModal(user, accountsSidebar)
        // }
      }
    }
    else {
      this._modalManage.current.toggleModal()
    }
  }

  componentDidMount() {
    if (window !== "undefined") {
      window.addEventListener("resize", this.updateWidth, false)
    }
    this.checkDevice()
    this.setState({ isLoading: true})
    // balanceServices.checkBalance().then(resp => {
    //   if (resp.status) {
    //     this.setState({ data: resp.data, status: resp.status })
    //   } else {
    //     this.setState({ status: resp.status })
    //   }
    // }, error => {
    //   this.setState({ isLoading: false })
    // })
    let user = JSON.parse(localStorage.getItem('USER_SETTING'))
    if (user.business.isGenerated === true) {
      this.props.getSaldo()
    }
  }


  checkDevice = () => {
    var prefixes = " -webkit- -moz- -o- -ms- ".split(" ")
    var mq = function (query) {
      return window.matchMedia(query).matches
    }

    if ("ontouchstart" in window || window.DocumentTouch) {
      this.setState({
        ScrollbarTag: "div"
      })
    } else {
      this.setState({
        ScrollbarTag: PerfectScrollbar
      })
    }
    var query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join(
      ""
    )
    return mq(query)
  }

  componentWillUnmount() {
    if (window !== "undefined") {
      window.removeEventListener("resize", this.updateWidth, false)
    }
  }

  changeActiveIndex = id => {
    if (id !== this.state.activeIndex) {
      this.setState({
        activeIndex: id
      })
    } else {
      this.setState({
        activeIndex: null
      })
    }
  }

  handleSidebarMouseEnter = id => {
    if (id !== this.state.hoveredMenuItem) {
      this.setState({
        hoveredMenuItem: id
      })
    } else {
      this.setState({
        hoveredMenuItem: null
      })
    }
  }

  handleActiveItem = url => {
    this.setState({
      activeItem: url
    })
  }

  render() {
    
    let {
      visibilityState,
      toggleSidebarMenu,
      sidebarHover,
      toggle,
      color,
      sidebarVisibility,
      activeTheme,
      collapsed,
      activePath,
      sidebarState,
      currentLang,
      role,
      permissions,
      data,
      isLoading,
      collapsedMenuPaths
    } = this.props
    let {
      menuShadow,
      activeIndex,
      hoveredMenuItem,
      activeItem,
      ScrollbarTag
    } = this.state
    let scrollShadow = (container, dir) => {
      if (container && dir === "up" && container.scrollTop >= 100) {
        this.setState({ menuShadow: true })
      } else if (container && dir === "down" && container.scrollTop < 100) {
        this.setState({ menuShadow: false })
      } else {
        return
      }
    }
    let isOpen =  localStorage.getItem("IS_OPEN") 
    return (
      <ContextLayout.Consumer>
        {context => {
          let dir = context.state.direction
          return (
            <React.Fragment>
              <Hammer onSwipe={e => { sidebarVisibility() }}
                direction={dir === "rtl" ? "DIRECTION_LEFT" : "DIRECTION_RIGHT"}>
                <div className="menu-swipe-area d-xl-none d-block vh-100"></div>
              </Hammer>
              <div
                className={classnames(
                  `main-menu menu-light theme-${activeTheme}`,
                  {
                    'menu-absolute': isOpen === "false",
                    'menu-fixed': isOpen === "true" ,
                    collapsed: sidebarState === true,
                    "hide-sidebar": this.state.width < 1200 && visibilityState === false
                  }
                )}
                onMouseEnter={() => sidebarHover(false)}
                onMouseLeave={() => sidebarHover(true)}
              >
                <SidebarHeader
                  toggleSidebarMenu={toggleSidebarMenu}
                  toggle={toggle}
                  sidebarBgColor={color}
                  sidebarVisibility={sidebarVisibility}
                  activeTheme={activeTheme}
                  collapsed={collapsed}
                  menuShadow={menuShadow}
                  activePath={activePath}
                  sidebarState={sidebarState}
                />
                <ScrollbarTag
                  className={classnames("main-menu-content", {
                    "overflow-hidden": ScrollbarTag !== "div",
                    "overflow-scroll": ScrollbarTag === "div"
                  })}
                  {...(ScrollbarTag !== "div" && {
                    options: { wheelPropagation: false },
                    onScrollDown: container =>
                      scrollShadow(container, "down"),
                    onScrollUp: container => scrollShadow(container, "up"),
                    onYReachStart: () =>
                      menuShadow === true &&
                      this.setState({ menuShadow: false })
                  })} >
                  <Hammer
                    onSwipe={() => {
                      sidebarVisibility()
                    }}
                    direction={dir === "rtl" ? "DIRECTION_RIGHT" : "DIRECTION_LEFT"} >
                    <ul className="navigation navigation-main">
                      {
                        <div data-tut="data" className="d-block saldo-section">
                        <Row>
                          <Col>
                            <p><img src={IconKipa} alt="" className="mr-1" /> 
                              Saldo Kipa 
                              {
                              (role === "cashier" && this.props.userSetting.permission.cashier.accessKipaBalanceDetail.value === true) ? 
                              <NavLink className="primary font-normal float-right" tag="a" onClick={e => history.push("/saldo")}>
                                Lihat
                              </NavLink>
                              : role !== "cashier" ?
                              <NavLink className="primary font-normal float-right" tag="a" onClick={e => history.push("/saldo")}>
                                Lihat
                              </NavLink>
                              : ""
                              }
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mr-1" sm="1">
                          </Col>
                          <Col >
                            {isLoading ? <Spinner size="sm" color="primary" /> : <RupiahLabel className="saldo-text-sidebar" value={data ? (data.balance && parseFloat(data.balance.totalBonus) + parseFloat(data.balance.totalTopup)) : 0} />}
                          </Col>
                        </Row>
                        {role !== "cashier" &&
                          <Button className="button-topup" color="primary" onClick={e => this.showModal(this.props.currentUser.business.package)}>
                            Top Up
                          </Button>}
                        </div>
                      }
                      
                      <SideMenuContent
                        setActiveIndex={this.changeActiveIndex}
                        activeIndex={activeIndex}
                        hoverIndex={hoveredMenuItem}
                        handleSidebarMouseEnter={this.handleSidebarMouseEnter}
                        activeItemState={activeItem}
                        handleActiveItem={this.handleActiveItem}
                        activePath={activePath}
                        lang={currentLang}
                        permissions={permissions}
                        role={role}
                        collapsedMenuPaths={collapsedMenuPaths}
                        toggleMenu={sidebarVisibility}
                        deviceWidth={this.props.deviceWidth}
                      />
                    </ul>
                  </Hammer>
                </ScrollbarTag>
              </div>
              <ManageTopup user={this.props.user} userSetting={this.props.userSetting} ref={this._modalManage}/>
              <ManageConfirm data={this.state.data} ref={this._modalConfirm} />
            </React.Fragment>
          )
        }}
      </ContextLayout.Consumer>
    )
  }
}

const mapStateToProps = state => {
  return {
    data: state.auth.getSaldo.values ? state.auth.getSaldo.values.data : {},
    isLoading: state.auth.getSaldo.isLoading,
    currentUser: jwt.decode(localStorage.getItem("USER_TOKEN")),
  }
}

export default connect(mapStateToProps, { getSaldo })(Sidebar)
