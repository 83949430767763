// import { authHeader } from '../_helpers';
import { mainConfig } from '../configs';
import { authHeader, handleResponse} from './authHeader';

export const referralServices = {
    getReferral,
    getReferralTrans,
    getDetailRefferal,
    getWithDraw,
    addWithDraw,
    addReferralCode
};

function getReferral(page = 1, per_page = 10, sort ="", search="", id="") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/referrals/${id}?page=${page}&per_page=${per_page}&sort=${sort}&search=${encodeURIComponent(search)} `, reqOpt).then(handleResponse)
}

function getReferralTrans(page = 1, per_page = 10, sort ="", search="", downlineId="") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/referrals/transaction?page=${page}&per_page=${per_page}&sort=${sort}&downlineId=${downlineId}&search=${encodeURIComponent(search)}`, reqOpt).then(handleResponse)
}
function getDetailRefferal() {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/referrals/detail`, reqOpt).then(handleResponse)
}
function getWithDraw(page = 1, per_page = 10, sort ="", search="") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/referrals/withdrawal?page=${page}&per_page=${per_page}&sort=${sort}&search=${encodeURIComponent(search)}`, reqOpt).then(handleResponse)
}

function addWithDraw(amount) {
    const reqOpt = {
        method: 'POST',
        headers: authHeader(), 
        body: JSON.stringify({ amount })
    }
    return fetch(`${mainConfig.apiUrl}/referrals/withdrawal`, reqOpt).then(handleResponse)
}


function addReferralCode(referralCode) {
    const reqOpt = {
        method: 'POST',
        headers: authHeader(), 
        body: JSON.stringify({ referralCode })
    }
    return fetch(`${mainConfig.apiUrl}/referrals`, reqOpt).then(handleResponse)
}




