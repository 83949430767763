import { mainConfig } from '../configs';
import { authHeader, handleResponse} from './authHeader';

export const balanceServices = {
    getHistory,
    topUpBalance,
    getSaldoList,
    getAccount,
    // checkBalance,
    confirmations,
    getPaymentUrl
};

function getHistory(page = 1, limit = 10, startData="", endDate="", status="", sort = "", search = ""){
    const reqOpt = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/topup?page=${page}&per_page=${limit}&sort=${sort}&search=${encodeURIComponent(search)}&startDate=${startData}&endDate=${endDate}&status=${status}`, reqOpt).then(handleResponse)
}
// function checkBalance(){
//     const reqOpt = {
//         method: 'GET',
//         headers: authHeader()
//     }

//     return fetch(`${mainConfig.apiUrl}/account/deposit`, reqOpt).then(handleResponse)
// }

function getSaldoList(page = 1, limit = 10, type="",startData="", endDate=""){
    const reqOpt = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/balance/detail?page=${page}&per_page=${limit}&type=${type}&startDate=${startData}&endDate=${endDate}`, reqOpt).then(handleResponse)
}

// function topUpBalance( amount) {
//     const reqOpt = {
//         method: 'POST',
//         headers: authHeader(),
//         body: JSON.stringify({amount})
//     }

//     return fetch(`${mainConfig.apiUrl}/account/deposit`, reqOpt).then(handleResponse)
// }

function topUpBalance( name,amount, bankAccount, accountNumber, isAutomatic, voucherCode) {
    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ name,amount, bankAccount, accountNumber, isAutomatic, voucherCode })
    }

    return fetch(`${mainConfig.apiUrl}/topup`, reqOpt).then(handleResponse)
}

function confirmations(id,accountNumber){
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({accountNumber})
    }

    return fetch(`${mainConfig.apiUrl}/account/deposit/${id}`, reqOpt).then(handleResponse)
}

function getPaymentUrl(id){
    const reqOpt = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/topup/payment-url/${id}`, reqOpt).then(handleResponse)
}


function getAccount(){
    const reqOpt = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/account`, reqOpt).then(handleResponse)
}

