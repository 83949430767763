// import { authHeader } from '../_helpers';
import { mainConfig } from '../configs';
import { authHeader, handleResponse} from './authHeader';

export const correctServices = {
    getCorrectStock,
    viewCorrectStock,
    addCorrectStock, 
    deleteCorrectStok
};

function getCorrectStock(page = 1, limit = 10, locationId = -1, startDate = "", endDate = "", sort = "", search = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/stockopname?page=${page}&per_page=${limit}&locationId=${locationId}&startDate=${startDate}&endDate=${endDate}&sort=${sort}&search=${encodeURIComponent(search)}`, reqOpt).then(handleResponse)
}

function viewCorrectStock(id) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/stockopname/${id}`, reqOpt).then(handleResponse)
}

function deleteCorrectStok(id) {
    const reqOpt = {
        method: 'DELETE',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/stockopname/${id}`, reqOpt).then(handleResponse)
}


function addCorrectStock(data, products, totalAdjust) {
    let checkFinalTotal = products.reduce((a, b) => a + (((b["quantity"] || 0) - (b["oldQty"] || 0)) * (b["avcoPrice"] || 0)), 0)
    const body = {
        transactionDate: data.transactionDate,
        locationId: data.location.value,
        finalTotal: checkFinalTotal,
        note: data.notes,
        products: products.map(item => {
            return {
                productId: item.productId,
                quantity: item.quantity - item.oldQty,
                unitPrice: item.avcoPrice,
                note:"",
                enableStock: true,
                isUnique : item.isUnique,
                inventoryUniqueIn : item.inventoryUniqueTransaction.filter(it => it.isNew === true).map(items =>{
                    return {name:items.name}
                }),
                inventoryUniqueOut : item.inventoryUniqueTransaction.filter(it => it.isDeleted === true && it.isNew === false).map(items =>{
                    return {name:items.name}
                })
            }
        })
    }

    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(body)
    }
    
    return fetch(`${mainConfig.apiUrl}/stockopname`, reqOpt).then(handleResponse)
}
