import React from "react"
import Select from "react-select"

class SelectDropdown extends React.Component {
    
    render() {
        let groupedOptions = [{
            label:"",
            options : this.props.options}
        ]
        return (
            <Select
                noOptionsMessage={() => "Data Tidak Ditemukan"}
                options={groupedOptions}
                placeholder={this.props.placeholder}
                value={this.props.options.filter(opt => opt.value === this.props.value)}
                onChange={this.props.onChange}
                required={this.props.required}
                isDisabled={this.props.isDisabled}
                className={this.props.className}
                isSearchable
                theme={theme => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: '#FC692B',
                        primary25: '#f7f7fa',
                        neutral20: '#d4d5e5',
                        neutral30: '#d4d5e5',
                        neutral50: '#2e2e2e'
                    }
                })}
            />
        )
    }
}

export default SelectDropdown