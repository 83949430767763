// import { authHeader } from '../_helpers';
import { mainConfig } from '../configs';
import { authHeader, handleResponse } from './authHeader';
// import { fireAnalytics } from "../configs/firebaseConfig"
// import * as firebase from 'firebase'
// import 'firebase/analytics'
import { cuan } from "../configs/cuanConfig"
import 'dotenv/config'
import { decrypt } from "../utility/Encryptor"
export const userService = {
    login,
    logout,
    register,
    getSaldo,
    editProfile,
    getProfile,
    forgotPassword,
    setPassword,
    verificationEmail,
    getAddress,
    setBusiness,
    verificationEmails
};

function login(username, password, token) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-CLIENT-TYPE': 'web', 'X-CLIENT-KEY': token },
        body: JSON.stringify({ username, password })
    };

    return fetch(`${mainConfig.apiUrl}/auth/login`, requestOptions)
        .then(handleResponseLogin)
        .then(async user => {
            // if(process.env.NODE_ENV !== "development"){
            //     fireAnalytics.logEvent(firebase.analytics.EventName.LOGIN, {
            //         method: "web"
            //     });
            //     fireAnalytics.logEvent("user_sign_in", {
            //         method: "web"
            //     });
            // }

            localStorage.clear()
            localStorage.setItem('USER_DATA', JSON.stringify({ firstName: user.data.user.name }))
            localStorage.setItem('USER_TOKEN', user.data.token)
            localStorage.setItem('USER_SETTING', JSON.stringify(user.data.user))
            localStorage.setItem('PERMISSION', JSON.stringify(user.data.user.business.permission))
            localStorage.setItem('PERMITTED_LOCATION', JSON.stringify(user.data.user.userLocation))
            const userDecode = decrypt(user.data.user.business.package)
            if (userDecode === "enterprise") {
                const accounts = await cuan().listAccount({
                    page: 1,
                    per_page: -2
                })
                let account = accounts.data.filter(it => it.accountNumber !== "1-10001")|| [1, 13, 14, 15].includes(it.category.id)
                const trxType = await cuan().listTransactionType({})
                localStorage.setItem('USER_ACCOUNTS', JSON.stringify(account))
                localStorage.setItem('USER_ACCOUNT_TYPE', JSON.stringify(trxType.data))
            }

            return user;
        });
}

function logout() {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${mainConfig.apiUrl}/auth/logout`, requestOptions)
        .then(async resp => {
            localStorage.clear()
        });
}

function editProfile(name, email, password, mobile, pin) {
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ name, email, password, mobile,pin })
    }

    return fetch(`${mainConfig.apiUrl}/users`, reqOpt).then(handleResponse)
}

function getProfile() {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/users`, reqOpt).then(handleResponse)
}

function register(data, token) {
    const reqOpt = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'X-CLIENT-TYPE': 'web',
            'X-CLIENT-KEY': token
        }
    }
    return fetch(`${mainConfig.apiUrl}/auth/register`, reqOpt).then(handleResponseLogin).then(user => {

        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.clear()
        localStorage.setItem('PERMITTED_LOCATION', JSON.stringify(user.data.user.business.permission))
        localStorage.setItem('USER_DATA', JSON.stringify({ firstName: user.data.user.name}))
        localStorage.setItem('USER_TOKEN', user.data.token)
        localStorage.setItem('USER_SETTING', JSON.stringify(user.data.user))
        localStorage.setItem('PERMISSION', JSON.stringify(user.data.user.business.permission))
        if (process.env.NODE_ENV !== "development") {
            // fireAnalytics.logEvent(firebase.analytics.EventName.SIGN_UP, {
            //     method: "web"
            // });
            // fireAnalytics.logEvent("user_sign_up", {
            //     method: "web"
            // });
        }

        return user;
    });
}
function getAddress(limit = 500) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/address?per_page=${limit}`, reqOpt).then(handleResponse)
}

function getSaldo() {
    let user = JSON.parse(localStorage.getItem("USER_SETTING"))
    let hash = user.hash
    const reqOpt = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(`${mainConfig.apiUrl}/balance?hash=${hash}`, reqOpt).then(handleResponse)
}



function setBusiness(businessCategoryId, address, city, state) {
    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ businessCategoryId, address, city, state })
    }

    return fetch(`${mainConfig.apiUrl}/business`, reqOpt).then(handleResponse)
}

function handleResponseLogin(response) {
    return response.text().then(async text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.clear();
                // window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function forgotPassword(email) {
    const reqOpt = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
    }
    return fetch(`${mainConfig.apiUrl}/auth/forgot`, reqOpt).then(handleResponse)
}
function setPassword(token, password) {
    const reqOpt = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, password })
    }
    return fetch(`${mainConfig.apiUrl}/auth/reset`, reqOpt).then(handleResponse)
}
function verificationEmails(email, id) {
    const reqOpt = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
    }
    return fetch(`${mainConfig.apiUrl}/auth/send-verification/${id}`, reqOpt).then(handleResponse)
}
function verificationEmail(token) {
    const reqOpt = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token })
    }
    return fetch(`${mainConfig.apiUrl}/auth/verify`, reqOpt).then(handleResponse)
}