// import { authHeader } from '../_helpers';
import { mainConfig } from '../configs';
import { authHeader, handleResponse} from './authHeader';

export const 
employeeServices = {
    getEmployee,
    addEmployee,
    deleteEmployee,
    updateEmployee,
    resetEmployee
};

function getEmployee(page = 1, limit = 10, sort = "", search = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/employees?page=${page}&per_page=${limit}&sort=${sort}&search=${encodeURIComponent(search)}`, reqOpt).then(handleResponse)
}

function addEmployee(data){
    delete data.id

    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    }

    return fetch(`${mainConfig.apiUrl}/employees`, reqOpt).then(handleResponse)
}

function deleteEmployee(id){
    const reqOpt = {
        method: 'DELETE',
        headers: authHeader()
    }
    return fetch(`${mainConfig.apiUrl}/employees/${id}`, reqOpt).then(handleResponse)
}

function updateEmployee( data){
    const id = data.id
    delete data.id
    
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    }
    return fetch(`${mainConfig.apiUrl}/employees/${id}`, reqOpt).then(handleResponse)
}


function resetEmployee(id) {
    const reqOpt = {
        method: 'DELETE',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/employees/reset/${id}`, reqOpt).then(handleResponse)
}


