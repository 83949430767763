// import { authHeader } from '../_helpers';
import { mainConfig } from '../configs';
import { authHeader, handleResponse} from './authHeader';

export const outletService = {
    getOutlet,
    getOutletEmployee,
    addOutlet,
    editOutlet,
    setActive,
    deleteOutlet,
    imageOutlet
};

function getOutlet(page = 1, limit = 10, sort = "", search = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/outlets?page=${page}&per_page=${limit}&sort=${sort}&search=${encodeURIComponent(search)}`, reqOpt).then(handleResponse)
}
function getOutletEmployee(page = 1, limit = 10) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/outlets?page=${page}&per_page=${limit}`, reqOpt).then(handleResponse)
}

function addOutlet(data) {
    data.mobile = data && data.mobile ? data.mobile : ""
    delete data.id
    delete data.image
    
    const reqOpt = {
        method: 'POST',
        headers: authHeader(), 
        body: JSON.stringify(data)
    }
    return fetch(`${mainConfig.apiUrl}/outlets`, reqOpt).then(handleResponse)
}

function setActive(id, isActive) {
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(), 
        body: JSON.stringify({isActive})
    }
    return fetch(`${mainConfig.apiUrl}/outlets/${id}/setActive`, reqOpt).then(handleResponse)
}
function deleteOutlet(id) {
    const reqOpt = {
        method: 'DELETE',
        headers: authHeader()
    }
    return fetch(`${mainConfig.apiUrl}/outlets/${id}`, reqOpt).then(handleResponse)
}

function editOutlet(data) {
    const id = data.id
    data.mobile = data.mobile ? data.mobile : ""
    delete data.id
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(), 
        body: JSON.stringify({
            name: data.name,
            mobile : data.mobile,
            state: data.state,
            city: data.city,
            address: data.address,
            marketplaceUrl:data.marketplaceUrl
        })
    }
    return fetch(`${mainConfig.apiUrl}/outlets/${id}`, reqOpt).then(handleResponse)
}

function imageOutlet(id, formData) {
    const data = new FormData()
    data.append('image', formData)
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(true), 
        body: data
    }
    return fetch(`${mainConfig.apiUrl}/outlets/upload-image/${id}`, reqOpt).then(handleResponse)
}



