import React from "react"
import {
    Row, Col, Card, CardBody,
    Modal, ModalHeader, ModalBody, ModalFooter, Label, FormGroup, Container,
    InputGroup, InputGroupAddon, Form, Alert
} from "reactstrap"
import { Input } from "reactstrap"
import ButtonBatalkan from "../../../components/custom/buttons/ButtonBatalkan"
import ButtonTambah from "../../../components/custom/buttons/ButtonTambah"
import bca from "../../../../src/assets/img/logo/bca.png"
import { balanceServices } from "../../../services/balance.services"
import { toast } from "react-toastify"
import RupiahInput from "../../../components/custom/Text/RupiahInput"
import SelectDropdown from "../../../components/custom/Select/SelectDropdown"
import Radio from "../../../components/@vuexy/radio/RadioVuexy"
import { ReactComponent as Help } from "../../../components/custom/Icon/help.svg"
class ManageModal extends React.Component {
    state = {
        isLoading: false,
        name: "",
        amount: 0,
        note: "",
        accounts: [],
        noRek: "",
        voucherCode:"",
        accountNumber: -1,
        isAutomatic: false
    }

    toggleModal = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }))
    }
    handleChange = (e) => {
        if (e.label) {
            e = {
                target: {
                    name: e.name,
                    value: e.value
                }
            }
        }
    }

    componentDidMount() {
        if (this.props.user === "enterprise" || this.state.user === "enterprise") {
            const accStr = localStorage.getItem("USER_ACCOUNTS")
            if (accStr) {
                const accounts = JSON
                    .parse(accStr)
                    .filter(it => it.accountNumber !== "1-10001" && it.category.id === 1)
                    .map(item => {
                        return { label: item.name, name: "accountNumber", value: item.accountNumber }
                    })

                accounts.unshift({ value: "-1", name: "accountNumber", label: "Pilih Akun Keuangan" })
                this.setState({ accounts })
            }
        }
    }
    topUpBalance = e => {
        e.preventDefault()
        this.setState({ isLoading: true })
        balanceServices.topUpBalance(this.state.name, this.state.amount, this.state.noRek, this.props.user === "enterprise" ? this.state.accountNumber : "", this.state.isAutomatic, this.state.voucherCode)
            .then(resp => {
                if (resp.data.paymentUrl !== "") {
                    window.open((resp.data.paymentUrl), "_blank")
                }
                this.toggleModal()

                this.setState({
                    isLoading: false,
                    data: null,
                    name: "",
                    amount: 0,
                    noRek: "",
                    voucherCode:""
                })

                toast.success(resp.message, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    autoClose: 2500,

                })
            }, error => {
                this.toggleModal()
                this.setState({
                    isLoading: false,
                    data: null,
                    name: "",
                    amount: 0,
                    noRek: "",
                    voucherCode:""
                })

                toast.error(error, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    autoClose: 2000
                })
            })
    }

    render() {
        return (
            <React.Fragment>
                <Modal
                    isOpen={this.state.isOpen}
                    toggle={this.toggleModal}
                    className="modal-dialog-centered">
                    <ModalHeader toggle={this.toggleModal}>
                        <span className="font-bold-18 margin-left-right-14">Top Up Saldo</span>
                    </ModalHeader>
                    <Form onSubmit={this.topUpBalance}>
                        <ModalBody>
                            <FormGroup>
                                <Container>
                                    <Row>
                                        <Col>
                                            <Label className="font-reg-14" for="namagroup">Nama Lengkap<span className="mandatory">*</span></Label>
                                            <Input
                                                type="text"
                                                placeholder="Sesuai nama direkening"
                                                name="name"
                                                value={this.state.name}
                                                onChange={e => this.setState({ name: e.target.value })}
                                                required
                                            />
                                        </Col>
                                        {this.state.isAutomatic === false &&
                                            <Col>
                                                <Label className="font-reg-14" for="namagroup">Nomor Rekening <span className="mandatory">*</span></Label>
                                                <Input
                                                    type="number"
                                                    placeholder="Rekening Anda"
                                                    name="noRek"
                                                    value={this.state.noRek}
                                                    onChange={e => this.setState({ noRek: e.target.value })}
                                                    required
                                                />
                                            </Col>
                                        }
                                    </Row>
                                    <Row className="mt-1">
                                        <Col>
                                            <Label className="font-reg-14" for="namagroup">Jumlah Top Up <span className="mandatory">*</span></Label>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">Rp</InputGroupAddon>
                                                <RupiahInput
                                                    placeholder="0"
                                                    type="number"
                                                    name="amount"
                                                    value={this.state.amount}
                                                    onChange={e => this.setState({ amount: e.floatValue ? e.floatValue : 0 })} required />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row className="mt-1">
                                        <Col>
                                                <FormGroup>
                                                    <Label for="name">Kode Voucher</Label>
                                                    <Input
                                                        placeholder="Masukkan kode voucher jika ada"
                                                        name="voucherCode"
                                                        value={this.state.voucherCode}
                                                        onChange={e => this.setState({ voucherCode: e.target.value })}
                                                    />
                                                </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="mt-1">
                                        <Col>
                                            { this.props.user === "enterprise"
                                                &&
                                                <FormGroup>
                                                    <Label for="name">Akun Keuangan</Label>
                                                    <SelectDropdown
                                                        options={this.state.accounts}
                                                        placeholder="Pilih Akun Keuangan"
                                                        value={this.state.accountNumber}
                                                        onChange={e => this.setState({ accountNumber: e.value })}
                                                        required />
                                                </FormGroup>
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label for="name">Metode Pembayaran</Label>
                                            <InputGroup className="mb-1">
                                                <Radio
                                                    name="showkipa"
                                                    color="primary"
                                                    checked={this.state.isAutomatic === false}
                                                    value={this.state.isAutomatic}
                                                    onChange={e => {
                                                        let isAutomatic
                                                        isAutomatic = false;
                                                        this.setState({ isAutomatic })
                                                    }}
                                                    className="primary"
                                                    label="Verifikasi Manual" />
                                                <Radio
                                                    name="showkipa"
                                                    color="primary"
                                                    checked={this.state.isAutomatic === true}
                                                    value={this.state.isAutomatic}
                                                    onChange={e => {
                                                        let isAutomatic
                                                        isAutomatic = true;
                                                        this.setState({ isAutomatic })
                                                    }}
                                                    className="primary ml-2"
                                                    label="Verifikasi Otomatis" />
                                            </InputGroup>

                                        </Col>
                                    </Row>
                                    {this.state.isAutomatic === false ?
                                        <Row className="mt-2">
                                            <Col>
                                                <Card className="mb-0" style={{ backgroundColor: '#f7f7fa' }}>
                                                    <CardBody className="p-1">
                                                        <p className="black text-center font-normal text-bold-500">Silahkan melakukan pembayaran ke rekening berikut</p>
                                                        <p className="text-center"><img src={bca} alt="bcaImg" /></p>
                                                        <Row className="mt-2">
                                                            <Col>
                                                                <p className="gray-400 font-normal" style={{ lineHeight: "2rem" }}>Nomor Rekening<br /><span className="white" style={{
                                                                    backgroundColor: "#3EB981",
                                                                    padding: "8px 12px",
                                                                    borderRadius: "4px",
                                                                }}>8161 855 677 <span className="ml-1 mr-1">|</span>  <span style={{ cursor: "pointer" }} onClick={() => {
                                                                    navigator.clipboard.writeText("8161855677").then(it => {
                                                                        toast.success('Rekening berhasil disalin', {
                                                                            position: toast.POSITION.BOTTOM_CENTER,
                                                                            autoClose: 2500,
                                                                            pauseOnFocusLoss: false
                                                                        })
                                                                    })
                                                                }}>Salin</span></span></p>
                                                                <p></p>
                                                            </Col>
                                                            <Col>
                                                                <p className="gray-400 font-normal" style={{ lineHeight: "2rem" }}>Atas Nama<br /><span className="black text-bold-500">PT. Kipa Teknologi Indonesia</span></p>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mt-1">
                                                            <Col md="1">
                                                                <Help color="#555597" style={{ verticalAlign: "middle", marginTop: 20, color: '#555597' }} size={20} />
                                                            </Col>
                                                            <Col>
                                                                <span style={{ color: '#555597' }} className="font-normal">Lakukan konfirmasi melalui chat WA CS Kipa setelah kamu melakukan top up ya. Top up akan diverifikasi dalam kurun waktu maksimal 1 x 24 jam</span>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                        :
                                        <>
                                            {JSON.parse(localStorage.getItem('USER_SETTING')).emailVerifiedAt === null &&
                                                <Alert color="primary"><span style={{ color: "#626262", fontSize: 14 }} >Verifikasi emailmu untuk menggunakan pembayaran otomatis! </span></Alert>
                                            }
                                            <Row style={{ backgroundColor: '#f7f7fa' , padding:10}} >
                                            
                                                <Col md="1">
                                                    <Help color="#555597" style={{ verticalAlign: "middle", marginTop: 60, color: '#555597' }} size={20} />
                                                </Col>
                                                <Col>
                                                    <span style={{ color: '#555597' }} className="font-normal">Pastikan jumlah yang anda masukkan sudah benar, setelah anda menekan tombol Topup Saldo anda akan dialihkan ke website penyedia pembayaran kami.</span>
                                                    <br/>
                                                    <br/>
                                                    <span style={{ color: '#555597' }} className="font-normal">Topup Saldo Otomatis akan dikenakan biaya layanan yang akan dikembalikan berupa Saldo Bonus.</span>
                                                    <br/>
                                                    <a href="https://kipa.id/topup" target="_blank">
                                                    <span className="font-normal">Baca Selengkapnya...</span>
                                                    </a>
                                                   
                                                </Col>
                                            </Row>

                                        </>
                                    }

                                </Container>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <ButtonBatalkan onClick={this.toggleModal} />
                            <ButtonTambah isDisabled={(JSON.parse(localStorage.getItem('USER_SETTING')).emailVerifiedAt === null && this.state.isAutomatic === true) || ( this.props.user === "enterprise" && +this.state.accountNumber === -1 )} type="submit" text="Top Up Saldo" isLoading={this.state.isLoading} />
                        </ModalFooter>
                    </Form>
                </Modal>

            </React.Fragment>
        )
    }
}

export default ManageModal