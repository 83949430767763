import { mainConfig } from '../configs';
import { authHeader, handleResponse} from './authHeader';

export const sellReturnService = {
    getReturnSell,
    getView,
    add,
    deleteReturn,
    getSellDigital,
    getDetailRetutSell
};

function getReturnSell(page = 1, limit = 10, customerId = -1, locationId = -1, paymentStatus = '', startDate = "", endDate = "", sort="", search="" ) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/sell-return?page=${page}&per_page=${limit}&customerId=${customerId}&locationId=${locationId}&paymentStatus=${paymentStatus}&startDate=${startDate}&endDate=${endDate}&search=${encodeURIComponent(search)}&sort=${sort}`, reqOpt).then(handleResponse)
}
function getSellDigital(page = 1, limit = 10, locationId = -1, paymentStatus = "", startDate = "", endDate = "",  sort="",search="", ) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/sells/digital?page=${page}&per_page=${limit}&locationId=${locationId}&status=${paymentStatus}&startDate=${startDate}&endDate=${endDate}&sort=${sort}&search=${encodeURIComponent(search)}`, reqOpt).then(handleResponse)
}

function add(body) {
    
    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(body)   
    }

    return fetch(`${mainConfig.apiUrl}/sell-return`, reqOpt).then(handleResponse)
}

function getView(id) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/sells/${id}`, reqOpt).then(handleResponse)
}


function getDetailRetutSell(id) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/sell-return/${id}`, reqOpt).then(handleResponse)
}



function deleteReturn(id) {
    const reqOpt = {
        method: 'DELETE',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/sell-return/${id}`, reqOpt).then(handleResponse)
}
