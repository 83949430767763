// import { authHeader } from '../_helpers';
import { mainConfig } from '../configs';
import { authHeader, handleResponse } from './authHeader';
import moment from "moment-timezone"

export const purchaseServices = {
    getPurchases,
    viewPurchase,
    addPurchase,
    deletePurchase,
    updateStatus,
    editPurchase
};

function getPurchases(page = 1, limit = 10, supplierId = -1, locationId = -1, paymentStatus = '', startDate = "", endDate = "", sort = "", search = "", status = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/purchases?page=${page}&per_page=${limit}&supplierId=${supplierId}&locationId=${locationId}&paymentStatus=${paymentStatus}&startDate=${startDate}&endDate=${endDate}&sort=${sort}&search=${encodeURIComponent(search)}&status=${status}`, reqOpt).then(handleResponse)
}

function viewPurchase(id) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(`${mainConfig.apiUrl}/purchases/${id}`, reqOpt).then(handleResponse)
}

function deletePurchase(id) {
    const reqOpt = {
        method: 'DELETE',
        headers: authHeader()
    }
    return fetch(`${mainConfig.apiUrl}/purchases/${id}`, reqOpt).then(handleResponse)
}

function editPurchase(id, data, products, finalTotal, totalBeforeDiscount) {
    let checkFinalTotal = products.reduce((a, b) => a + ((b["quantity"] || 0) * (b["unitPrice"] || 0)), 0)
    const body = {
        locationId: data.locationId,
        transactionDate: data.transactionDate,
        status: data.status,
        contactId: data.contactId,
        discountType: data.discountType,
        discountAmount: data.discountAmount,
        payTermNumber: data.payTermNumber,
        payTermType: data.payTermType,
        note: data.note,
        finalTotal: checkFinalTotal - data.discountAmount,

        products: products.map(item => {
            return {
                enableStock: item.enableStock,
                quantity: item.quantity,
                note: item.note,
                unitPriceBeforeDiscount: item.unitPrice,
                unitPrice: item.unitPrice,
                productId: item.productId,
                discountType: item.discountType,
                isUnique:item.isUnique,
                discountAmount: item.discountAmount,
                discountId: item.discountId,
                inventoryUniqueTransaction: item.isUnique === true ? 
                item.inventoryUniqueTransaction.map((items)=>{
                    return {name:items.name}
                })
                : []
            }
        }),
        payment: data.payment = {
            amount: data.payAmount,
            method: data.paymentMethod,
            accountNumber: data.accountNumber
        },
        totalBeforeDiscount: checkFinalTotal,
    }
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(body)
    }
    return fetch(`${mainConfig.apiUrl}/purchases/update/${id}`, reqOpt).then(handleResponse)
}
function addPurchase(data, products, finalTotal, totalBeforeDiscount) {
    let checkFinalTotal = products.reduce((a, b) => a + ((b["quantity"] || 0) * (b["unitPrice"] || 0)), 0)
    const body = {
        locationId: data.locationId,
        transactionDate: moment().format("YYYY-MM-DD HH:mm:ss"),
        status: data.status,
        contactId: data.contactId,
        discountType: data.discountType,
        discountAmount: data.discountAmount,
        payTermNumber: data.payTermNumber,
        payTermType: data.payTermType,
        note: data.note,
        finalTotal: checkFinalTotal - data.discountAmount,

        products: products.map(item => {
            return {
                enableStock: item.enableStock,
                quantity: item.quantity,
                note: item.note,
                unitPriceBeforeDiscount: item.unitPrice,
                unitPrice: item.unitPrice,
                productId: item.productId,
                discountType: item.discountType,
                discountAmount: item.discountAmount,
                discountId: item.discountId,
                isUnique:item.isUnique,
                inventoryUniqueTransaction: item.isUnique === true ? item.inventoryUniqueTransaction : []
            }
        }),
        payment: data.payment = {
            amount: data.payAmount,
            method: data.paymentMethod,
            accountNumber: data.accountNumber
        },
        totalBeforeDiscount: checkFinalTotal,
    }
    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(body)
    }

    return fetch(`${mainConfig.apiUrl}/purchases`, reqOpt).then(handleResponse)
}

function updateStatus(item) {
    const reqOpt = {
        method: 'PUT',
        headers: authHeader()
    }
    return fetch(`${mainConfig.apiUrl}/purchases/${item.id}`, reqOpt).then(handleResponse)
}
