// import { authHeader } from '../_helpers';
import { mainConfig } from '../configs';
import { authHeader, handleResponse} from './authHeader';

export const settingService = {
    resetTransaction,
    resetData,
    resetKeuangan,
    deleteLogo
};

function resetTransaction(password) {
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ password })
    }

    return fetch(`${mainConfig.apiUrl}/business/transaction-reset`, reqOpt).then(handleResponse)
}

function resetData(password) {
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(), 
        body: JSON.stringify({ password })
    }
    return fetch(`${mainConfig.apiUrl}/business/data-reset`, reqOpt).then(handleResponse)
}

function resetKeuangan(password) {
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(), 
        body: JSON.stringify({ password })
    }
    return fetch(`${mainConfig.apiUrl}/business/cuan-reset`, reqOpt).then(handleResponse)
}
function deleteLogo(){
    const reqOpt = {
        method: 'DELETE',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/invoices/delete-logo`, reqOpt).then(handleResponse)
}

