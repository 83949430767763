import { mainConfig } from '../configs';
import { authHeader, handleResponse} from './authHeader';

export const categoryService = {
    
    getCategory,
    addCategory,
    updateCategory,
    destroy
};
function getCategory(page = 1, limit = 10, sort = "", search = "", type = ""){
    const reqOpt = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/categories?page=${page}&per_page=${limit}&sort=${sort}&search=${encodeURIComponent(search)}&type=${type}`, reqOpt).then(handleResponse)
}

function addCategory(name) {
    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ name})
    }

    return fetch(`${mainConfig.apiUrl}/categories`, reqOpt).then(handleResponse)
}

function updateCategory(id, name) {
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ name })
    }

    return fetch(`${mainConfig.apiUrl}/categories/${id}`, reqOpt).then(handleResponse)
}

function destroy(id) {
    const reqOpt = {
        method: 'DELETE',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/categories/${id}`, reqOpt).then(handleResponse)
}