// import { authHeader } from '../_helpers';
import { mainConfig } from '../configs';
import { authHeader, handleResponse} from './authHeader';

export const transferServices = {
    getTransferStock,
    viewTransferStock,
    addTransferStock,
    changeStatus,
    deleteTransferStok
};

function viewTransferStock(id, filter) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/stocktransfers/${id}?type=${filter}`, reqOpt).then(handleResponse)
}

function deleteTransferStok(id) {
    const reqOpt = {
        method: 'DELETE',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/stocktransfers/${id}`, reqOpt).then(handleResponse)
}

function getTransferStock(page = 1, limit = 10, fromLocId = -1, toLocId = -1, startDate = "", endDate = "", sort = "", search = "", status = "", admin ="") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/stocktransfers?page=${page}&per_page=${limit}&sort=${sort}&search=${encodeURIComponent(search)}&fromLocId=${fromLocId}&toLocId=${toLocId}&startDate=${startDate}&endDate=${endDate}&status=${status}&createdBy=${admin}`, reqOpt).then(handleResponse)
}

function addTransferStock(data, products, totalTransfer, status) {
    let checkFinalTotal = products.reduce((a, b) => a + ((b["quantity"] || 0) * (b["avcoPrice"] || 0)), 0)
    const body = {
        transactionDate: data.transactionDate,
        fromLocationId: data.fromLocation.value,
        toLocationId: data.toLocation.value,
        finalTotal: checkFinalTotal,
        status:status,
        note: data.notes,
        products: products.map(item => {
            return {
                productId: item.productId,
                quantity: item.quantity,
                unitPrice: item.avcoPrice,
                note:"",
                enableStock:true,
                isUnique: item.isUnique,
                inventoryUniqueTransaction: item.isUnique === true ? 
                item.inventoryUniqueTransaction.map((items)=>{
                    return {name:items.name}
                })
                : []
            }
        })
    }
    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(body)
    }

    return fetch(`${mainConfig.apiUrl}/stocktransfers`, reqOpt).then(handleResponse)
}

function changeStatus(id) {
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(), 
    }
    return fetch(`${mainConfig.apiUrl}/stocktransfers/${id}`, reqOpt).then(handleResponse)
}