export function authHeader(isImage = false) {
    // return authorization header with jwt token
    let token = localStorage.getItem("USER_TOKEN")
    if (token) {
        let head = { 
            'pragma': 'no-cache',
            'cache-control': 'no-cache',
            'Authorization': 'Bearer ' + token,
            'X-VERSION' : '3.4.5'
         }

         if(!isImage) 
         head = {
             ...head,
             'pragma': 'no-cache',
             'cache-control': 'no-cache',
             'Content-Type': 'application/json'
         }

        return head;
    } else {
        return {};
    }
}

export function handleResponseToken(response) {
    let userSetting = response.headers.get('USER_SETTING')
    if(userSetting){
        localStorage.setItem('USER_SETTING', userSetting)
    }

    return response.text().then(text => {
        let data
        // if(text === "Unauthorized")
            // data = { message: "Unathorized", status: false, data: null}
        // else
            data = text && JSON.parse(text);
            
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.clear()
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

export function handleResponse(response) {
    return response.text().then(text => {
        let data
        if(text === "Unauthorized")
            data = { message: "Unathorized", status: false, data: null}
        else
            data = text && JSON.parse(text);
            
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.clear()
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}