import React from "react"
import NumberFormat from "react-number-format"
import { Input } from "reactstrap"
  
class RupiahInput extends React.Component {
    validation = (e) => {
        if (e.keyCode === 189) {
            e.preventDefault()
        }
    }

    render() {
        return(
            <NumberFormat disabled={this.props.disabled} onKeyDown={e => this.props.enableMinus === true ?  "" : this.validation(e)} name={this.props.name} required={this.props.required} onValueChange={this.props.onChange} value={this.props.value} customInput={Input} displayType={"input"} thousandSeparator={true} />
        )
    }
}

export default RupiahInput