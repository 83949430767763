export const register = (state = { isLoading: false }, action) => {
    switch (action.type) {
      case "REGISTER_REQUEST": {
        return { ...state, isLoading: true, values: action.payload }
      }
      case "REGISTER_SUCCESS": {
        return { ...state, isLoading: false, values: action.payload }
      }
      case "REGISTER_FAILURE": {
        return {...state, isLoading: false, values: action.payload }
      }
      default: {
        return state
      }
    }
  }
  