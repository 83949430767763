import React from "react"
import {
    Row, Col, Card, CardBody,
    Modal, ModalHeader, ModalBody, ModalFooter, Label, FormGroup, Container,
    Form,
} from "reactstrap"
import ButtonTambah from "../../../components/custom/buttons/ButtonTambah"
import bca from "../../../../src/assets/img/logo/bca.png"
import { balanceServices } from "../../../services/balance.services"
import { toast } from "react-toastify"
import copy from "copy-to-clipboard"
import ManageFailed from "../History/Manage"
import { Copy, Info } from "react-feather"
import "../../../assets/scss/plugins/forms/flatpickr/flatpickr.scss"
import moment from "moment-timezone"
import ButtonBatalkan from "../../../components/custom/buttons/ButtonBatalkan"

class ManageConfirm extends React.Component {
    constructor(props) {
        super(props)
        this._modalManageFailed = React.createRef()
    }
    state = {
        isLoading: false,
        name: "",
        amount: 0,
        note: "",
        noRek: "",
        disable: false,
        errorMessage: "",
        accounts: [],
        accountNumber: "",
        user: "",
        accountsSidebar: [],
        data: this.props.data
    }


    toggleModal = (user, accountsSidebar) => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
            user: user,
            accountsSidebar: accountsSidebar
        }))
    }

    componentDidMount() {
        if (this.props.user === "enterprise" || this.state.user === "enterprise") {
            const accStr = localStorage.getItem("USER_ACCOUNTS")
            if (accStr) {
                const accounts = JSON
                    .parse(accStr)
                    .filter(it => it.accountNumber !== "1-10001" && it.category.id === 1)
                    .map(item => {
                        return { label: item.name, name: "accountNumber", value: item.accountNumber }
                    })

                accounts.unshift({ value: "-1", name: "accountNumber", label: "Pilih Akun Keuangan" })
                this.setState({ accounts })
            }
        }
    }
    showModalFailed = () => {
        this._modalManageFailed.current.toggleModal()
    }

    topUpBalance = e => {
        e.preventDefault()
        balanceServices.confirmations(this.state.data.id, "").then(resp => {
            this.toggleModal()
            toast.success(resp.message, {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                autoClose: 2500,

            })
        }, error => {
            this.toggleModal()
            toast.error(error, {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                autoClose: 2000
            })
        })
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            this.setState({ data: this.props.data })
        }
    }
    copyReferral = event => {
        let data = this.state.data
        copy(data && data.amount)
             toast.success('Link referral berhasil disalin', {
                 position: toast.POSITION.TOP_CENTER,
                 autoClose: 1500,
                 pauseOnFocusLoss: false
             })
    }   
    render() {
        let { data } = this.state
        let date = data && moment.utc(data.transferBefore).format("dddd, DD MMMM YYYY")
        let time = data && moment.utc(data.transferBefore).format("hh:mm")
        let amount = Math.round(data && data.amount).toLocaleString()
        var lastIndex = amount.lastIndexOf(",")
        var codeUniq = amount.substring(lastIndex + 1)
        var number = amount.substr(0, lastIndex);
        return (
            <React.Fragment>
                <Modal
                    isOpen={this.state.isOpen}
                    toggle={this.toggleModal}
                    className="modal-dialog-centered">
                    <ModalHeader toggle={this.toggleModal}>
                        <span className="margin-left-right-14">Top Up Saldo</span>
                    </ModalHeader>
                    <Form onSubmit={this.topUpBalance}>
                        <ModalBody>
                            <div className="step-bar mt-2 text-center">
                                    <div className="font-normal">1</div>
                                    <div className="font-normal ">2</div>
                            </div>
                            <span className="d-inline font-normal black margin-100">Nominal Topup</span>
                            <span className="d-inline font-normal ml-3 black ">Pembayaran Topup</span>
                            <FormGroup className="mt-2">
                                <Container>
                                    <Row>
                                        <Col className="text-center">
                                            <Label className="font-normal grey" for="namagroup">Jumlah Bayar</Label>
                                            <br />
                                            <span className="font-medium-6 medium mr-1 black">{number},<span style={{ backgroundColor: "#fc692b", color: "white", borderRadius: "4px" }}>{codeUniq}</span></span>
<Copy size={22} onClick={this.copyReferral} />
                                        </Col>
                                    </Row>
                                    <Row className="mt-1">
                                        <Col className="text-center"><p className="font-normal">Transfer tepat sampai 3 digit terakhir untuk mempermudah proses verifikasi, jika terdapat kesalahan transfer,
                                            <span className="font-normal primary d-inline-block" style={{cursor:"pointer"}} onClick={this.showModalFailed}> Lapor Kesalahan Transfer ! </span></p>
                                        </Col>
                                    </Row>
                                    <Row className="mt-1">
                                        <Col>
                                            <Card className="mb-0" style={{ backgroundColor: '#f7f7fa' }}>
                                                <CardBody className="p-1">
                                                    <p className="black text-center font-normal text-bold-500">Silahkan melakukan pembayaran ke rekening berikut</p>
                                                    <p className="text-center"><img src={bca} alt="bcaImg" /></p>
                                                    <Row className="mt-2">
                                                        <Col>
                                                            <p className="gray-400 font-normal" style={{ lineHeight: "2rem" }}>Nomor Rekening<br /><span className="white" style={{
                                                                backgroundColor: "#3EB981",
                                                                padding: "8px 12px",
                                                                borderRadius: "4px",
                                                            }}>8161 855 677 <span className="ml-1 mr-1">|</span>  <span style={{ cursor: "pointer" }} onClick={() => {
                                                                copy("8161855677", {
                                                                    onCopy: () => {
                                                                        toast.success('Nomor Rekening berhasil disalin', {
                                                                            position: toast.POSITION.TOP_CENTER,
                                                                            autoClose: 1500,
                                                                            pauseOnFocusLoss: false
                                                                        })
                                                                    }
                                                                })
                                                            }}>Salin</span></span></p>
                                                            <p></p>
                                                        </Col>
                                                        <Col>
                                                            <p className="gray-400 font-normal" style={{ lineHeight: "2rem" }}>Atas Nama<br /><span className="black text-bold-500">PT. Kipa Teknologi Indonesia</span></p>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="mt-1">
                                        <Col>
                                            <div className="p-1" style={{ background: "#f0f2f6", border: "1px solid #555597", borderRadius: "4px" }}>
                                                <Row>
                                                    <Col className='text-center' md="1"><Info size={16} color="#555597" className="ml-1 mr-1 text-center d-inline" /></Col>
                                                    <Col> <span className="font-normal d-inline" style={{ color: "#555597" }} >
                                                        Selesaikan pembayaran sebelum <span className="primary bold">{date} Pukul {time} WIB </span> agar pembayaran tidak dibatalkan oleh sistem
                                            </span></Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <ButtonBatalkan text="Transfer Nanti" onClick={this.toggleModal}/>
                            <ButtonTambah type="submit" text="Saya Sudah Transfer" isLoading={this.state.isLoading} />
                        </ModalFooter >
                    </Form>
                </Modal>
                <ManageFailed ref={this._modalManageFailed} />
            </React.Fragment>
        )
    }
}

export default ManageConfirm