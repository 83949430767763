import { mainConfig } from '../configs';
import { authHeader, handleResponse} from './authHeader';

export const reportService = {
    getProductPurcase,
    getPurchaseDetail,
    getPurchaseTempo,
    getMinimumStocks,
    getStocks,
    getProductSells,
    getSellingDetail,
    getTrending,
    getOutlet,
    getSellTempo,
    getSellCashier,
    getReportCashierShift,
    getReportCashier,
    getDashboardChart,
    getProfitLoss,
    getSellMetode,
    getSellReturDetail,
    getDetailTempo,
    getNetProfit, 
    getSellCategory,
    getCardStock,
    getProductExpired,
    getProductPrice,
    getSellCustomer
};

function getNetProfit(startDate = '', endDate = '') {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/reports/netprofit`, reqOpt).then(handleResponse)
}

function getProductPurcase(page = 1, limit = 10, locationId = -1, categoryId = -1, startDate = "",endDate="", sort = "", search = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/reports/purchase-product?page=${page}&per_page=${limit}&categoryId=${categoryId}&locationId=${locationId}&startDate=${startDate}&endDate=${endDate}&sort=${sort}&search=${encodeURIComponent(search)}`, reqOpt).then(handleResponse)
}

function getPurchaseDetail(page = 1, limit = 10, supplierId = -1,locationId = -1, startDate = "",endDate="", sort = "", search = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/reports/purchase-detail?page=${page}&per_page=${limit}&supplierId=${supplierId}&locationId=${locationId}&startDate=${startDate}&endDate=${endDate}&sort=${sort}&search=${encodeURIComponent(search)}`, reqOpt).then(handleResponse)
}

function getPurchaseTempo(page = 1, limit = 10, locationId = -1, contactId = -1, sort = "", search = "", startDate="", endDate="") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/reports/purchase-tempo?page=${page}&per_page=${limit}&contactId=${contactId}&locationId=${locationId}&sort=${sort}&search=${encodeURIComponent(search)}&startDate=${startDate}&endDate=${endDate}`, reqOpt).then(handleResponse)
}

function getDashboardChart(filter = 'days', grossProfitChart, sellTrxChart) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/reports/dashboard-chart?filter=${filter}&grossProfitChart=${grossProfitChart}&sellTrxChart=${sellTrxChart}&new=${1}`, reqOpt).then(handleResponse)
}

function getMinimumStocks(page = 1, limit = 10,locationId = -1, categoryId = -1, sort = "", search = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/reports/stock-min?page=${page}&per_page=${limit}&categoryId=${categoryId}&locationId=${locationId}&sort=${sort}&search=${encodeURIComponent(search)}`, reqOpt).then(handleResponse)
}

function getStocks(page = 1, limit = 10, locationId = -1, categoryId = -1, sort = "", search = "", endDate="", enableStock="") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/reports/stock?page=${page}&per_page=${limit}&categoryId=${categoryId}&locationId=${locationId}&sort=${sort}&search=${encodeURIComponent(search)}&endDate=${endDate}&enableStock=${enableStock}`, reqOpt).then(handleResponse)
}

function getProductSells(page = 1, limit = 10, locationId = -1, categoryId = -1, startDate = "",endDate="", sort = "", search = "", cashierId = "", priceGroupId = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/reports/sell-product?page=${page}&per_page=${limit}&categoryId=${categoryId}&locationId=${locationId}&startDate=${startDate}&endDate=${endDate}&sort=${sort}&search=${encodeURIComponent(search)}&cashierId=${cashierId}&priceGroupId=${priceGroupId}`, reqOpt).then(handleResponse)
}



function getSellingDetail(page = 1, limit = 10,locationId = -1, customerId = -1, startDate = "",endDate="", sort = "", search = "", paymentStatus = "", productId = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/reports/sell-detail?page=${page}&per_page=${limit}&customerId=${customerId}&locationId=${locationId}&startDate=${startDate}&endDate=${endDate}&sort=${sort}&search=${encodeURIComponent(search)}&paymentStatus=${paymentStatus}&productId=${productId}`, reqOpt).then(handleResponse)
}

function getTrending(page = 1, limit = 10, locationId = -1, categoryId = -1, startDate = "",endDate="", sort = "", search = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/reports/sell-trending?page=${page}&per_page=${limit}&locationId=${locationId}&categoryId=${categoryId}&startDate=${startDate}&endDate=${endDate}`, reqOpt).then(handleResponse)
}

function getOutlet(page = 1, limit = 10, startDate = "",endDate="", sort = "", search = "", productId = "", categoryId = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/reports/sell-outlet?page=${page}&per_page=${limit}&startDate=${startDate}&endDate=${endDate}&sort=${sort}&search=${encodeURIComponent(search)}&productId=${productId}&categoryId=${categoryId}`, reqOpt).then(handleResponse)
}

function getSellTempo(page = 1, limit = 10,  locationId = -1, customerId = -1, sort = "", search = "", startDate = "",endDate="") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/reports/sell-tempo??page=${page}&per_page=${limit}&customerId=${customerId}&locationId=${locationId}&sort=${sort}&search=${encodeURIComponent(search)}&startDate=${startDate}&endDate=${endDate}`, reqOpt).then(handleResponse)
}

function getSellCashier(page = 1, limit = 10, locationId = -1, cashierId = 1, startDate = "",endDate="", sort = "", search = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/reports/sell-cashier?page=${page}&per_page=${limit}&locationId=${locationId}&cashierId=${cashierId}&startDate=${startDate}&endDate=${endDate}&sort=${sort}&search=${encodeURIComponent(search)}`, reqOpt).then(handleResponse)
}
function getReportCashierShift(page = 1, limit = 10, userId = -1, locationId = '', startDate = "",endDate="", sort = "", search = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/reports/session?page=${page}&per_page=${limit}&userId=${userId}&locationId=${locationId}&startDate=${startDate}&endDate=${endDate}&sort=${sort}&search=${encodeURIComponent(search)}`, reqOpt).then(handleResponse)
}

function getReportCashier(page = 1, limit = 10, locationId = -1, startDate = "", endDate = "", sort = "", search = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/reports/cashier-cash-report?page=${page}&per_page=${limit}&locationId=${locationId}&startDate=${startDate}&endDate=${endDate}&sort=${sort}&search=${encodeURIComponent(search)}`, reqOpt).then(handleResponse)
}

function getProfitLoss(locationId = -1, startDate = "", endDate = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/reports/profit-loss?locationId=${locationId}&startDate=${startDate}&endDate=${endDate}`, reqOpt).then(handleResponse)
}

function getSellMetode(page = 1, limit = 10, startDate = "", endDate = "", sort = "", search = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/reports/sell-method?page=${page}&per_page=${limit}&startDate=${startDate}&endDate=${endDate}&sort=${sort}&search=${encodeURIComponent(search)}`, reqOpt).then(handleResponse)
}

function getSellReturDetail(page = 1, limit = 10, locationId = -1, customerId = -1 , startDate = "",endDate="", sort = "", search = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/reports/sell-return-detail?page=${page}&per_page=${limit}&customerId=${customerId}&locationId=${locationId}&startDate=${startDate}&endDate=${endDate}&sort=${sort}&search=${encodeURIComponent(search)}`, reqOpt).then(handleResponse)
}

function getSellCategory(page = 1, limit = 10,locationId = -1, startDate = "",endDate="", sort = "", search = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/reports/sell-category?page=${page}&per_page=${limit}&locationId=${locationId}&startDate=${startDate}&endDate=${endDate}&sort=${sort}&search=${encodeURIComponent(search)}`, reqOpt).then(handleResponse)
}

function getSellCustomer(page = 1, limit = 10,startDate = "",endDate="", sort = "", search = "", categoryId = "", productId = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/reports/sell-customer?page=${page}&per_page=${limit}&startDate=${startDate}&endDate=${endDate}&sort=${sort}&search=${encodeURIComponent(search)}&categoryId=${categoryId}&productId=${productId}`, reqOpt).then(handleResponse)
}

function getDetailTempo(page = 1, limit = 10, locationId = -1, customerId = -1 , startDate = "",endDate="", sort = "", search = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/reports/sell-tempo-detail?page=${page}&per_page=${limit}&customerId=${customerId}&locationId=${locationId}&startDate=${startDate}&endDate=${endDate}&sort=${sort}&search=${encodeURIComponent(search)}`, reqOpt).then(handleResponse)
}
function getCardStock(productId = -1 ,locationId = -1, categoryId = -1,  startDate = "",endDate="") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/reports/stock-detail?productId=${productId}&locationId=${locationId}&categoryId=${categoryId}&startDate=${startDate}&endDate=${endDate}`, reqOpt).then(handleResponse)
}

function getProductExpired(page = 1, limit = 10,locationId = -1, startDate = "",endDate="", sort = "", search = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/reports/product-expired?page=${page}&per_page=${limit}&locationId=${locationId}&startDate=${startDate}&endDate=${endDate}&sort=${sort}&search=${encodeURIComponent(search)}`, reqOpt).then(handleResponse)
}

function getProductPrice(page = 1, limit = 10,locationId = -1, sort = "", search = "", priceGroupId = "", categoryId ="", type = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/reports/product-price?page=${page}&per_page=${limit}&locationId=${locationId}&sort=${sort}&search=${encodeURIComponent(search)}&priceGroupId=${priceGroupId}&categoryId=${categoryId}&type=${type}`, reqOpt).then(handleResponse)
}
 
 
 
