import React from "react"
import ButtonBatalkan from "../buttons/ButtonBatalkan"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from "reactstrap"

class ConfirmationModal extends React.Component {
    state = {
        isOpen: false,
        isLoading: false
    }

    toggleModal = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }))
    }


    deleteItem = () => {
        this.props.onDeleted(this.state.id)
    }

    render() {
        return (
            <Modal
                    isOpen={this.state.isOpen}
                    toggle={this.toggleModal}
                    className="modal-dialog-centered">
                    <ModalHeader toggle={this.toggleModal}>
                        <span className="margin-left-right-14">{this.props.title}</span>
                    </ModalHeader>
                    <ModalBody className="margin-left-right-14">
                        <span className="font-normal">{this.props.content}</span>
                    </ModalBody>
                    <ModalFooter className="margin-left-right-14">
                        <ButtonBatalkan onClick={this.toggleModal}></ButtonBatalkan>
                        <Button.Ripple className="font-normal text-bold-500" color="primary" onClick={this.props.onClick}>
                            {this.props.isLoading && <Spinner color="white" size="sm" />}
                            {this.props.btnOkText}
                        </Button.Ripple>
                    </ModalFooter>
            </Modal>
        )
    }
}

export default ConfirmationModal