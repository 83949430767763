import { mainConfig } from '../configs';
import { authHeader, handleResponse} from './authHeader';

export const sellService = {
    getSell,
    getReturnSell,
    getView,
    deleteSell,
    editSell
};

function getSell(page = 1, limit = 10, customerId = -1, locationId = -1, paymentStatus = '', startDate = "", endDate = "", sort = "", search = "", status = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/sells?page=${page}&per_page=${limit}&customerId=${customerId}&locationId=${locationId}&paymentStatus=${paymentStatus}&startDate=${startDate}&endDate=${endDate}&sort=${sort}&search=${encodeURIComponent(search)}&status=${status}`, reqOpt).then(handleResponse)
}

function getReturnSell(page = 1, limit = 10, customerId = -1, locationId = -1, paymentStatus = '', date = [], sort = "", search = "") {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/sell-return?page=${page}&per_page=${limit}&customerId=${customerId}&locationId=${locationId}&paymentStatus=${paymentStatus}&startDate=${date[0]}&endDate=${date[1]}&sort=${sort}&search=${encodeURIComponent(search)}`, reqOpt).then(handleResponse)
}

function editSell(id,data, products, finalTotal, totalBeforeDiscount) {
    const body = {
        locationId	:	data.locationId,
        transactionDate : data.transactionDate,
        status	:	data.status,
        contactId	:	data.contactId,
        discountType	:	data.discountType,
        discountAmount	:	data.discountAmount,
        payTermNumber	:	data.payTermNumber,
        payTermType	:	data.payTermType,
        note	:	data.note,
        finalTotal	:	finalTotal,

        products : products.map(item => {
            return {
                enableStock	:	item.enableStock,
                quantity	:	item.quantity,
                note	:	item.note,
                priceGroupId: item.priceGroupId,
                unitPriceBeforeDiscount	: item.unitPrice,
                unitPrice	:	item.unitPrice,
                productId	:	item.productId,
                discountType: item.discountType,
                isUnique : item.isUnique,
                discountAmount: item.discountAmount,
                discountId: item.discountId ===  null ? "-1" : item.discountId,  
                inventoryUniqueTransaction: item.isUnique === true ? 
                item.inventoryUniqueTransaction.map((items)=>{
                    return {name:items.name}
                })
                : []
            }
        }),
        payment :  data.payment = {
            amount: data.payAmount,
            method: data.paymentMethod
        },
        totalBeforeDiscount : totalBeforeDiscount,
    }
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(body)
    }
    return fetch(`${mainConfig.apiUrl}/sells/${id}`, reqOpt).then(handleResponse)
}

function getView(id) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/sells/${id}`, reqOpt).then(handleResponse)
}

function deleteSell(id) {
    const reqOpt = {
        method: 'DELETE',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/sells/${id}`, reqOpt).then(handleResponse)
}
