import { history } from "../../history"
import { userService } from '../../services'

export const login = user => {
  return dispatch => {
    dispatch({
      type: "LOGIN_REQUEST",
      payload: user
    })

    userService.login(user.username, user.password, user.token)
    .then( async user => {
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: {
          data: user.data,
          status: user.status,
          message: user.message
        }
      })
      localStorage.setItem('IS_OPEN', true)
      localStorage.setItem('IS_LOGIN', true)
      history.push('/dashboard')
      
    }, async error => {
      dispatch({
        type: "LOGIN_FAILURE",
        payload: {
          data: null,
          status: false,
          message: error.toString()
        }
      })
      //send error to frontend
    })
  }
}

export const logout = () => {
  return dispatch => {
    userService.logout().then(resp => {
      dispatch({
        type: "LOGOUT"
      })
    }, err => {
      dispatch({
        type: "LOGOUT"
      })
    })
  }
}

export const changeRole = role => {
  return dispatch => dispatch({ type: "CHANGE_ROLE", userRole: role })
}
