import React from "react"
import {ReactComponent as ContactIcon} from "../components/custom/Icon/kontak.svg"
import {ReactComponent as ProductIcon} from "../components/custom/Icon/product.svg"
import {ReactComponent as StockIcon} from "../components/custom/Icon/stok.svg"
import {ReactComponent as SellIcon} from "../components/custom/Icon/penjualan.svg"
import {ReactComponent as ReportIcon} from "../components/custom/Icon/laporan.svg"
import {ReactComponent as SettingIcon} from "../components/custom/Icon/icon_pengaturan.svg"
import {ReactComponent as IconHome } from "../components/custom/Icon/icon_home.svg"
import {ReactComponent as PackageIcon} from "../components/custom/Icon/choose_package.svg"
import {ReactComponent as CommisionIcon} from "../components/custom/Icon/icon_komisi.svg"
import {ReactComponent as ExpenseIcon } from "../components/custom/Icon/pengeluaran.svg"
import {ReactComponent as Help } from "../components/custom/Icon/help.svg"
const navigationConfig = [

  {
    id: "home",
    title: "Beranda",
    type: "item",
    icon: <IconHome className="sidebar-icon margin-right-14"/>,
    role: ["owner", "admin", "cashier"],
    navLink: "/dashboard",

  },
  
  {
    type: "groupHeader",
    groupTitle: "TRANSAKSI",
    role: ["owner", "admin", "cashier"],
    package: ["basic", "pro", "enterprise"]
    
  },

  {
    id: "penjualan",
    title: "Riwayat Penjualan",
    type: "item",
    icon: <SellIcon className="sidebar-icon margin-right-14"/>,
    role: ["owner", "admin", "cashier"],
    navLink: "/sales",
    package: ["basic", "pro", "enterprise"],

  },
  {
    id: "stok",
    title: "Pembelian & Stok",
    type: "item",
    icon: <StockIcon className="sidebar-icon margin-right-14"/>,
    role: ["owner", "admin", "cashier"],
    navLink: "/stocks",
    package: ["basic", "pro", "enterprise"]
  },
  {
    id: "costs",
    title: "Biaya & Pendapatan",
    type: "item",
    icon: <ExpenseIcon className="sidebar-icon margin-right-14"/>,
    role: ["owner", "admin", "cashier"],
    navLink: "/costs",
    package: ["basic","pro", "enterprise"]
  },
  {
    id: "accounts",
    title: "Keuangan",
    type: "item",
    icon: <ReportIcon className="sidebar-icon margin-right-14"/>,
    role: ["owner", "admin", "cashier"],
    navLink: "/account",
    package: ["enterprise"]
  },

  {
    id: "report",
    title: "Laporan",
    type: "item",
    icon: <ReportIcon className="sidebar-icon margin-right-14"/>,
    role: ["owner", "cashier", "admin"],
    navLink: "/reports",
    package: ["basic", "pro", "enterprise"]
  },
  // {
  //   id: "journal",
  //   title: "Jurnal",
  //   type: "item",
  //   icon: <JurnalIcon className="sidebar-icon margin-right-14"/>,
  //   role: ["owner", "admin"],
  //   navLink: "/journal",
  //   package: ["enterprise"]
  // },
  
  // {
  //   id: "report-accounting",
  //   title: "Neraca Keuangan",
  //   type: "item",
  //   icon: <ReportIcon className="sidebar-icon margin-right-14"/>,
  //   role: ["owner", "admin", "cashier",],
  //   package: ["enterprise"],
  //   navLink: "/account-report",
  // },
  // {
  //   id: "expense",
  //   title: "Biaya",
  //   type: "item",
  //   icon: <ExpenseIcon className="sidebar-icon margin-right-14"/>,
  //   role: ["owner", "editor"],
  //   navLink: "/biaya",
  // },
  // {
  //   id: "account-report",
  //   title: "Laporan",
  //   type: "collapse",
  //   icon: <ReportIcon className="sidebar-icon margin-right-14"/>,
  //   role: ["owner", "editor"],
  //   navLink: "/account-report",
  // },
  {
    type: "groupHeader",
    groupTitle: "PENGATURAN & LAYANAN",
    role: ["owner", "admin"],
    package: ["basic", "pro", "enterprise"]
  },
  {
    id: "contacts",
    title: "Supplier & Pelanggan",
    type: "item",
    icon: <ContactIcon className="sidebar-icon margin-right-14"/>,
    role: ["owner", "admin", "cashier"],
    navLink: "/contacts",
    package: ["pro", "enterprise"],

  },
  {
    id: "produk",
    title: "Produk",
    type: "item",
    icon: <ProductIcon className="sidebar-icon margin-right-14"/>,
    role: ["owner", "admin", "cashier"],
    navLink: "/products",
    package: ["basic", "pro", "enterprise"],
    step:"product"
  },
  {
    id: "setting",
    title: "Usaha",
    type: "item",
    icon: <SettingIcon className="sidebar-icon margin-right-14"/>,
    role: ["owner", "admin"],
    navLink: "/settings",
    package: ["basic", "pro", "enterprise"]
  },
  {
    id: "services",
    title: "Paket Layanan",
    type: "item",
    icon: <PackageIcon className="sidebar-icon margin-right-14"/>,
    role: ["owner"],
    navLink: "/services",
    package: ["basic", "pro", "enterprise"]
  },
  {
    id: "referral",
    title: "Komisi Referal",
    type: "item",
    icon: <CommisionIcon className="sidebar-icon margin-right-14" />,
    role: ["owner"],
    navLink: "/referral",
    package: ["basic", "pro", "enterprise"]
  },
  {
    id: "help",
    title: "Informasi dan Bantuan",
    type: "external-link",
    icon: <Help size={20} />,
    role: ["owner"],
    newTab : true,
    navLink: "https://kipa.id/bantuan",
    package: ["basic", "pro", "enterprise"]
  },
]
export default navigationConfig